import type {
  ExpertReviewQuery,
  ExpertReviewQueryVariables,
  ReportReviewMutation,
  ReportReviewMutationVariables,
  ReviewsAndAggregatedRatingsQuery,
  ReviewsAndAggregatedRatingsQueryVariables,
  ReviewsQuery,
  ReviewsQueryVariables,
  SizeFitness,
} from "react-app/src/generated/graphql-code-generator";
import { callGraphQL } from "react-app/src/graphql/graphqlApi";

const reviewsQueryFields = /* GraphQL */ `
  fragment reviewsQueryFields on PaginatedReviews {
    nextToken
    reviews {
      creationDate
      displayName
      headline
      helpfulVoteCount
      id
      isArticleRecommended
      rating
      sizeFitness
      text
      verifiedBuyer
      translations {
        headline
        language
        sourceLanguage
        text
      }
      unhelpfulVoteCount
    }
  }
`;

type GetExpertReviewProps = {
  siteId: string;
  articleNumber: string;
};

export const createReviewServiceClient = (
  aws_appsync_apiKey: string,
  aws_appsync_graphqlEndpoint: string
) => {
  return {
    ListReviews: async (
      productId: string,
      count: number,
      nextToken: string
    ) => {
      const query = /* GraphQL */ `
        query ReviewsAndAggregatedRatings(
          $reviewsFilter: ReviewsFilter!
          $aggregatedRatingsFilter: AggregatedRatingFilter!
          $limit: Int
          $nextToken: String
        ) {
          reviews(
            filter: $reviewsFilter
            limit: $limit
            nextToken: $nextToken
          ) {
            ...reviewsQueryFields
          }
          aggregatedRating(filter: $aggregatedRatingsFilter) {
            average
            quantity
            ratingQuantities {
              quantity
              rating
            }
            recommendationPercentage
            sizeRating {
              percentage
              sizeFitness
            }
          }
        }

        ${reviewsQueryFields}
      `;

      const queryInput = {
        articleNumber: {
          eq: productId,
        },
      };

      const variables: ReviewsAndAggregatedRatingsQueryVariables = {
        reviewsFilter: queryInput,
        aggregatedRatingsFilter: queryInput,
        limit: count,
        nextToken,
      };
      const { data } = await callGraphQL<ReviewsAndAggregatedRatingsQuery>(
        { query, variables, forceAnonymous: true },
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey
      );

      return {
        reviewsData: data?.reviews,
        aggregatedRating: data?.aggregatedRating,
      };
    },
    GetSizeRating: async (articleNr: string) => {
      type SizeRecommendationQuery = {
        aggregatedRating: {
          sizeRating: {
            percentage: number;
            sizeFitness: SizeFitness;
          }[];
        } | null;
      };
      const query = /* GraphQL */ `
        query SizeRecommendationQuery(
          $aggregatedRatingsFilter: AggregatedRatingFilter!
        ) {
          aggregatedRating(filter: $aggregatedRatingsFilter) {
            sizeRating {
              percentage
              sizeFitness
            }
          }
        }
      `;

      const queryInput = {
        articleNumber: {
          eq: articleNr,
        },
      };

      const variables = {
        aggregatedRatingsFilter: queryInput,
      };

      const { data } = await callGraphQL<SizeRecommendationQuery>(
        {
          query,
          variables,
        },
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey
      );

      return data?.aggregatedRating?.sizeRating;
    },
    ListAllReviews: async (productId: string, nextToken: string) => {
      const queryInput: ReviewsQueryVariables["filter"] = {
        articleNumber: {
          eq: productId,
        },
      };
      const query = /* GraphQL */ `
        query Reviews(
          $filter: ReviewsFilter!
          $limit: Int
          $nextToken: String
        ) {
          reviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
            ...reviewsQueryFields
          }
        }

        ${reviewsQueryFields}
      `;
      const limit = 50;
      const variables: ReviewsQueryVariables = {
        filter: queryInput,
        limit,
        nextToken,
      };

      const { data } = await callGraphQL<ReviewsQuery>(
        { query, variables },
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey
      );

      return { nextReviews: data?.reviews };
    },

    GetExpertReview: async ({
      siteId,
      articleNumber,
    }: GetExpertReviewProps) => {
      const query = /* GraphQL */ `
        query ExpertReview($input: ExpertReviewInput!) {
          expertReview(input: $input) {
            id
            text
            title
            store
            author
            modifiedTime
            creationDate
            helpfulVoteCount
            unhelpfulVoteCount
          }
        }
      `;

      const variables: ExpertReviewQueryVariables = {
        input: { articleNumber, siteId },
      };

      return await callGraphQL<ExpertReviewQuery>(
        { query, variables, forceAnonymous: true },
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey
      );
    },

    ReportReview: async ({
      reviewId,
      reportText,
    }: {
      reviewId: string;
      reportText: string;
    }) => {
      const mutation = /* GraphQL */ `
        mutation ReportReview($input: ReportReviewInput!) {
          reportReview(input: $input)
        }
      `;

      const variables: ReportReviewMutationVariables = {
        input: { reviewId, reportText },
      };

      return await callGraphQL<ReportReviewMutation>(
        {
          query: mutation,
          variables,
        },
        aws_appsync_graphqlEndpoint,
        aws_appsync_apiKey
      );
    },
  };
};
