import { log } from "@xxl/logging-utils";
import { Product } from "../../../../react-app/src/components/Product";
import { PriceWithLabels } from "../../../../react-app/src/components/Product/PriceWithLabels/PriceWithLabels";
import { getPriceData } from "../../../../react-app/src/components/Product/product-helper";
import type { ProductCardDataV2 } from "../../../../react-app/src/utils/ProductData/product-card-data-helper";

type ProductCardV2Props = {
  AddToCartRow?: JSX.Element;
  arrayIndex: number;
  isLaptopViewPort: boolean;
  isSliderProductList: boolean;
  nrOfSelectedColumns: number;
  productData: ProductCardDataV2;
  siteDefaultLanguage: string;
  toggleProductsAsPackageQuantity: boolean;
  hasRewardPrices?: boolean;
  isHoverable?: boolean;
};

export const ProductCardV2 = ({
  AddToCartRow,
  arrayIndex,
  isLaptopViewPort,
  isSliderProductList,
  nrOfSelectedColumns,
  productData,
  siteDefaultLanguage,
  toggleProductsAsPackageQuantity,
  hasRewardPrices,
  isHoverable,
}: ProductCardV2Props) => {
  const {
    additionalSales,
    campaignHighlightedLabel,
    campaignRibbon,
    code,
    price,
    type,
    version,
  } = productData;

  const priceData = getPriceData({
    version,
    priceData: price,
    productType: type,
    showPackagePrice: false,
    siteDefaultLanguage,
    toggleProductsAsPackageQuantity,
    units: undefined,
    campaignRibbon,
    campaignHighlightedLabel,
  });

  const hoverableState =
    isHoverable !== undefined ? isHoverable : isLaptopViewPort;

  if (priceData === null) {
    log.error(`Product with code ${code} is missing price data.`);
    return null;
  }

  const { colorTheme, highlightedLabel, priceSplash } = priceData;
  const nrOfImagesToPrefetch = isLaptopViewPort ? 6 : 2;
  const isPrio = arrayIndex !== -1 && arrayIndex < nrOfImagesToPrefetch;

  return (
    <Product
      additionalSales={additionalSales}
      AddToCartRow={AddToCartRow}
      isHoverable={hoverableState}
      isSliderProductList={isSliderProductList}
      PriceComponent={
        <PriceWithLabels
          version={2}
          selectedColumnsNumber={nrOfSelectedColumns}
          priceData={price}
          hasRewardPrices={hasRewardPrices}
        />
      }
      priceSplash={priceSplash}
      showHighlightedLabel={true} // TODO: https://xxlsports.atlassian.net/browse/XD-16091
      highlightedLabel={highlightedLabel}
      colorTheme={colorTheme}
      product={productData}
      productMetaData={{
        list: "search",
        pageType: "search",
        position: arrayIndex + 1,
      }}
      selectedFilters={[]} // TODO: XD-14109
      showFavoritesHeart={true}
      selectedColumnsNumber={nrOfSelectedColumns}
      prioritizeImageLoad={isPrio}
      positionInList={arrayIndex}
    />
  );
};
