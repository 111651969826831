import { isNotNullOrUndefined, hasValue } from "@xxl/common-utils";
import {
  BannerWithTextTypeEnum,
  type BannerWithText,
  type GridBanner,
} from "@xxl/content-api";
import HighlightedProductBanner from "react-app/src/components/Banners/HighlightedProductBanner/HighlightedProductBanner";
import ProductBanner from "react-app/src/components/Banners/ProductBanner/ProductBanner";
import RegularBanner from "react-app/src/components/Banners/RegularBanner/RegularBanner";
import ConditionalLink from "react-app/src/components/ConditionalLink/ConditionalLink";
import { resolveTextColor } from "react-app/src/components/GridComponent/GridComponent";
import type {
  BannerSize,
  ProductBannerPricing,
} from "react-app/src/components/PriceDisplays/price-display-helper";
import { withErrorBoundary } from "react-app/src/utils/WithErrorBoundary/with-error-boundary";
import {
  DisclaimerInformationWrapper,
  ExtraSmallBannerWrapper,
  MediumBannerWrapper,
  SmallBannerWrapper,
} from "./HomepageBannerWrappers.styled";
import { DisclaimerInformation } from "@/react-components/DisclaimerInformation";
import { useXxlMediaQuery } from "@/react-app/hooks/useXxlMediaQuery";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { log } from "@xxl/logging-utils";

type LegacyBannerProps = {
  banners: GridBanner[] | BannerWithText[];
  size: BannerSize;
  isHighPrioComponent: boolean;
  priceData?: ProductBannerPricing[];
};

const wrapper: {
  [key in BannerSize]:
    | typeof ExtraSmallBannerWrapper
    | typeof SmallBannerWrapper
    | typeof MediumBannerWrapper;
} = {
  xs: ExtraSmallBannerWrapper,
  small: SmallBannerWrapper,
  medium: MediumBannerWrapper,
};

type BannerVariantProps = {
  banner: GridBanner | BannerWithText;
  size: BannerSize;
  isHighPrioComponent: boolean;
  priceData?: ProductBannerPricing[];
};

const BannerVariant = ({
  banner,
  priceData = [],
  size,
  isHighPrioComponent,
}: BannerVariantProps) => {
  const regularBanners = ["gridBanner", "banner", "extendedBanner"];
  const nonHihglightedProductBanners = [
    "xsProductBanner",
    "smallProductBanner",
    "twoColumnGridProductBanner",
  ];

  if (!isNotNullOrUndefined(banner._type)) {
    log.error("Legacy banner is missing _type.");
    return null;
  }

  if (nonHihglightedProductBanners.includes(banner._type)) {
    const price = priceData.find(
      ({ productCode, type }) =>
        banner.product?.productCode === productCode && banner._type === type
    );

    if (!isNotNullOrUndefined(price)) {
      log.debug(`Banner of type "${banner._type}" has no price.`);
      return null;
    }

    // Narrowing down type, therefore explicit checks per type name required.
    if (
      price.type !== "xsProductBanner" &&
      price.type !== "smallProductBanner" &&
      price.type !== "twoColumnGridProductBanner"
    ) {
      log.error(
        `Banner of type "${banner._type}" has mismacthing price type "${price.type}"`
      );
      return null;
    }

    return (
      <ProductBanner
        headline={banner.text}
        label={banner.typeText}
        subtitle={banner.subtitle}
        backgroundColor={banner.backgroundColor?.value}
        buttonLabel={banner.button}
        cheapestPrice={price.prices.cheapestInRecentPast}
        countdownTimerSettings={banner.countdownTimerSettings}
        icon={banner.icon}
        priceData={price}
        product={banner.product}
        productBrand={banner.productBrand}
        productDescription={banner.productDescription}
        productName={banner.productName}
        size={size}
        textColor={resolveTextColor(banner.textColorPicker?.title)}
        isHighPrioComponent={isHighPrioComponent}
      />
    );
  }
  if (
    banner._type === BannerWithTextTypeEnum.highlightedSmallProductBanner ||
    banner._type === BannerWithTextTypeEnum.highlightedMediumProductBanner
  ) {
    const price = priceData.find(
      ({ productCode, type }) =>
        banner.product?.productCode === productCode && banner._type === type
    );

    if (!isNotNullOrUndefined(price)) {
      log.debug(`Banner of type "${banner._type}" has no price.`);
      return null;
    }
    if (
      price.type !== BannerWithTextTypeEnum.highlightedSmallProductBanner &&
      price.type !== BannerWithTextTypeEnum.highlightedMediumProductBanner
    ) {
      log.error(
        `Banner of type "${banner._type}" has mismacthing price type "${price.type}"`
      );
      return null;
    }

    return (
      <HighlightedProductBanner
        size={size}
        headline={banner.headline}
        label={banner.typeText}
        subtitle={banner.subtitle}
        textColor={resolveTextColor(banner.textColorPicker?.title)}
        product={banner.product}
        cheapestPrice={price.prices.cheapestInRecentPast}
        productName={banner.productName}
        productDescription={banner.productDescription}
        backgroundColor={banner.backgroundColor?.value}
        icon={banner.icon}
        logo={banner.logo}
        priceData={price}
        countdownTimerSettings={banner.countdownTimerSettings}
        isHighPrioComponent={isHighPrioComponent}
      />
    );
  }
  if (regularBanners.includes(banner._type)) {
    return (
      <RegularBanner
        tagline={banner.typeText}
        title={banner.text}
        description={banner.description}
        taglineField={banner.tagline}
        titleField={banner.titleField}
        descriptionField={banner.descriptionField}
        backgroundColor={banner.backgroundColor?.value}
        buttonText={banner.button}
        countdownTimerSettings={banner.countdownTimerSettings}
        hasImageOverlay={false}
        image={banner.image}
        mobileImage={banner.mobileImage}
        size={size}
        textColor={resolveTextColor(banner.textColorPicker?.title)}
        textPosition={banner.textPosition}
        url={banner.url}
        isHighPrioComponent={isHighPrioComponent}
        disclaimerInformation={banner.disclaimerInformation}
      />
    );
  }

  log.error("LegacyBanner type not recognised: ", banner._type);
  return null;
};
const _LegacyBanners = ({
  banners,
  priceData,
  size,
  isHighPrioComponent,
}: LegacyBannerProps) => {
  const { t } = useTranslations();
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const Wrapper = wrapper[size];
  return (
    <Wrapper>
      {banners.map((banner, idx) => {
        const url = banner.url ?? banner.product?.url;
        const hasBannerLink = isNotNullOrUndefined(url);

        return (
          <div key={idx} style={{ position: "relative" }}>
            {hasValue(banner.disclaimerInformation) && (
              <DisclaimerInformationWrapper>
                <DisclaimerInformation
                  buttonText={t("general.close")}
                  title={t("general.information")}
                  iconSize={isLaptop ? 24 : 18}
                  color={banner.disclaimerInformation.buttonColor}
                >
                  {banner.disclaimerInformation.description}
                </DisclaimerInformation>
              </DisclaimerInformationWrapper>
            )}

            <ConditionalLink url={url ?? ""} condition={hasBannerLink}>
              <BannerVariant
                banner={banner}
                priceData={priceData}
                size={size}
                isHighPrioComponent={isHighPrioComponent}
              />
            </ConditionalLink>
          </div>
        );
      })}
    </Wrapper>
  );
};

export const LegacyBanners = withErrorBoundary(_LegacyBanners);
