import { BASE_PRODUCT_SUFFIX } from "@/react-app/constants";
import { useApiClients } from "@/react-app/contexts/ApiClients";
import { ConfigurationModal } from "@/react-components/Product/ConfigurableProduct";
import type { ProductConfiguration } from "@/react-components/Product/ConfigurableProduct/ConfigurationModalWrapper";
import { appendTooltipsToConfigurations } from "@/react-components/Product/ConfigurableProduct/ConfigurationModalWrapper.helper";
import type { BusyState } from "@/react-hooks/useAddToCart/useAddToCart";
import { useElevateRequestData } from "@/react-hooks/useElevateRequestData/useElevateRequestData";
import { addEventListener, dispatchEvent, type } from "@/react-utils/xxl-event";
import { getBaseProductCodeWithoutSuffix } from "@/react-utils/xxl-shared-data";
import { useQueryClient } from "@tanstack/react-query";
import { hasNoValue, isNotNull } from "@xxl/common-utils";
import type { ConfigurationData } from "@xxl/product-search-api";
import noop from "lodash/noop";
import * as React from "react";
import { useState } from "react";
import { QUERY_KEYS } from "../queries/queryKeys";
import { useProductTooltipsQuery } from "../queries/useProductTooltipsQuery";
import type { Configuration } from "../types";

type ConfigurationModalWrapperProps = {
  configurations: ConfigurationData[];
  onClose?: () => void;
  onSubmit?: (configuration: Configuration) => Promise<void>;
  open?: boolean;
  setAddToCartState?: (state: BusyState) => void;
};

export const ConfigurationModalWrapper: React.FunctionComponent<
  ConfigurationModalWrapperProps
> = ({
  configurations,
  onClose = noop,
  onSubmit,
  open = false,
  setAddToCartState,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingSizes, setIsLoadingSizes] = useState(true);
  const [configuration, setConfiguration] = useState<
    ProductConfiguration[] | null
  >(null);

  const { elevateApi } = useApiClients();
  const queryClient = useQueryClient();
  const { data: tooltips, isLoading } = useProductTooltipsQuery({
    isEnabled: isOpen,
  });
  const { getBaseQuery } = useElevateRequestData();

  const handleOpenEvent = (): void => setIsOpen(true);
  const handleOnClose = (): void => {
    setIsOpen(false);
    onClose();
  };
  const defaultOnSubmit = (conf: Configuration) => {
    dispatchEvent(type.XXL_CONFIG_PRODUCT_SUBMIT, conf);
    setIsOpen(false);
    return Promise.resolve();
  };
  const handleSubmit = onSubmit ?? defaultOnSubmit;
  const handleReload = () => {
    void queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.PRODUCT_TOOLTIPS],
    });
  };

  React.useEffect(() => {
    addEventListener(type.XXL_CONFIG_PRODUCT_OPEN, handleOpenEvent);
    return (): void => {
      document.body.removeEventListener(
        type.XXL_CONFIG_PRODUCT_OPEN,
        handleOpenEvent
      );
    };
  }, []);

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  React.useEffect(() => {
    if (setAddToCartState === undefined) {
      return;
    }
    setAddToCartState(isLoading ? "LOADING_REGULAR_ADD_TO_CART" : "READY");
  }, [isLoading, setAddToCartState]);

  React.useEffect(() => {
    if (!isOpen || hasNoValue(tooltips) || hasNoValue(configurations)) {
      return;
    }
    // TODO: Use data from Elevate after https://xxlsports.atlassian.net/browse/XD-16756 is done
    void (async () => {
      const styles = configurations.map(({ style }) => style);
      const {
        data: {
          primaryList: { baseProducts },
        },
      } = await elevateApi.storefront.landingPagePOST(
        {
          ...getBaseQuery(),
          campaignIds: [],
          notify: false,
          toggleOverrides: {
            ignoreProductVisibilityRules: true,
          },
          channels: "ONLINE|STORE",
        },
        {
          primaryList: {
            include: true,
            productRules: `rule incl product_key {"${styles.join('" "')}"}`,
          },
        }
      );

      const mappedConfigurations = configurations
        .map(({ style, qualifier }) => {
          const {
            products: [product],
          } = baseProducts.find(
            ({ products: [{ code: productCode }] }) => style === productCode
          ) ?? { products: [] };
          if (hasNoValue(product)) {
            return null;
          }
          const { code, title, variants } = product;
          const sizeOptions = variants.map(
            ({ code: ean, url, label: size }) => ({
              code: url.split("=").at(-1),
              ean,
              size,
            })
          );

          return {
            product: {
              baseProductCode: `${getBaseProductCodeWithoutSuffix(code)}${BASE_PRODUCT_SUFFIX}`,
              baseProductName: title,
              sizeOptions,
            },
            qualifier,
          };
        })
        .filter(isNotNull);

      setConfiguration(
        appendTooltipsToConfigurations({
          configurations: mappedConfigurations,
          tooltips,
        })
      );
      setIsLoadingSizes(false);
    })();
  }, [configurations, elevateApi, isOpen, tooltips]);

  return (
    <ConfigurationModal
      configuration={configuration}
      isOpen={isOpen}
      isLoading={isLoading || isLoadingSizes}
      onSubmit={handleSubmit}
      onClose={handleOnClose}
      onReload={handleReload}
    />
  );
};
