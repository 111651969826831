import type { XXLCookie } from "@/react-app/global";
import type { GetServerSidePropsContext } from "next/types";
import { cookieNames } from "react-app/src/utils/Cookie";
import { getEnvVar } from "../environment-variables";
import { isLocalhost } from "../page-helper";

export const COOKIE_EXPIRATION_IN_SECONDS = 31556926;

const getCookieDomainLocal = (host: string | undefined): string =>
  host ?? "localhost.dev.aws.xxl.dk";

export const createXxlCookie = async (customerKey?: string) => {
  const now = Date.now();
  const cookieVersion = getEnvVar("CONFIG_SITE_COOKIEVERSION");
  const cookie = {
    cookieVersion,
    customerKey: customerKey ?? crypto.randomUUID(),
    lastModified: now,
    loggedIn: false,
    teamSalesData: { clubUIDs: [] },
    totalItems: 0,
    sessionId: crypto.randomUUID(),
  };

  return cookie;
};

export const getCookieDomain = (host: string | undefined): string =>
  ".".concat(
    (isLocalhost(host ?? "")
      ? getCookieDomainLocal(host)
      : process.env.ENV_DOMAIN_NAME
    )
      .split(".")
      .slice(1)
      .join(".")
  );

export const createCookieString = ({
  cookie,
  domain,
  doubleEncode = false,
  maxAge,
  name,
  path = "/",
  sameSite = "Lax",
}: {
  cookie: unknown;
  maxAge: number | string;
  name: string;
  domain?: string;
  doubleEncode?: boolean;
  path?: string;
  sameSite?: "Strict" | "Lax" | "None";
}) => {
  const cookieString = JSON.stringify(cookie);
  return `${name}=${doubleEncode ? JSON.stringify(cookieString) : cookieString}; Max-Age=${maxAge}; Path=${path}; Secure; SameSite=${sameSite}; ${domain !== undefined ? `Domain=${domain}` : ""}`;
};

export const createXxlCookieForHeader = (
  cookie: XXLCookie,
  req: GetServerSidePropsContext["req"]
) => {
  const { host = "" } = req.headers;
  const domain = host.startsWith("localhost")
    ? host.replace("localhost.", "")
    : host;

  return createCookieString({
    cookie,
    domain,
    maxAge: COOKIE_EXPIRATION_IN_SECONDS,
    name: cookieNames.XXL,
  });
};

export const checkIfIsLoggedIn = (authCookie: string | undefined): boolean =>
  authCookie !== undefined;
