import { color as colors } from "@xxl/theme";
import type { Nullable } from "@xxl/type-utils";
import type { Translate } from "react-app/src/contexts/Translations/TranslationsContext";
import { hexToRGB } from "react-app/src/utils/xxl-hex-to-rgb";
import type { DeepNonNullable } from "utility-types";

type WithBrands = {
  brands?: string[];
};
type WithCampaigns = {
  campaigns?: string[];
};
type WithCategories = {
  categories?: string[];
};
type WithStyle = {
  styleId: string;
};
type WithProducts = {
  includedProducts?: string[];
};
type UniqueUpsaleProps = WithCategories & WithStyle;
type UniqueRecentlyViewedProps = WithStyle;
type UniquePersonalizedProps = WithBrands &
  WithCampaigns &
  WithCategories &
  WithProducts;
type UniquePopularityProps = WithBrands & WithCampaigns & WithCategories;
type UniqueBestsellerProps = WithBrands & WithCampaigns & WithCategories;
type UniqueCartProps = {
  productIdsInCart: string[];
};

type CommonProps = {
  campaigns?: string[];
  colorTheme?: string;
  hasTopMargin?: boolean;
  includedProducts?: string[];
  /**
   * Max nr of products to show.
   */
  limit?: number;
  sliderConfig?: {
    slidesPerView: {
      laptop: number;
      mobile: number;
      tablet: number;
    };
    spacing: number;
  };
  sliderStyles?: React.CSSProperties;
  styleId?: string;
  subTitle?: string;
  type?: "SELF_CONTAINED" | "SUB_COMPONENT";
  title?: string;
};

type UseUniquePropsReturn = Nullable<DeepNonNullable<UniqueUpsaleProps>> &
  Nullable<DeepNonNullable<UniqueRecentlyViewedProps>> &
  Nullable<DeepNonNullable<UniquePersonalizedProps>> &
  Nullable<DeepNonNullable<UniqueBestsellerProps>> &
  Nullable<DeepNonNullable<UniquePopularityProps>> &
  Nullable<DeepNonNullable<UniqueCartProps>>;

export const RecommendationStrategies = {
  bestseller: "bestseller",
  cart: "cart",
  frequently_bought_together: "frequently-bought-together",
  personalized: "personalized",
  popularity: "popularity",
  recently: "recently",
  upsale: "upsale",
} as const;

export type HandlerProps =
  | (CommonProps & {
      strategy: typeof RecommendationStrategies.frequently_bought_together;
      styleId: string;
    })
  | (UniqueCartProps &
      CommonProps & {
        strategy: typeof RecommendationStrategies.cart;
      })
  | (UniqueUpsaleProps &
      CommonProps & { strategy: typeof RecommendationStrategies.upsale })
  | (UniqueRecentlyViewedProps &
      Omit<CommonProps, "includedProducts"> & {
        strategy: typeof RecommendationStrategies.recently;
      })
  | (UniquePersonalizedProps &
      CommonProps & { strategy: typeof RecommendationStrategies.personalized })
  | (UniquePopularityProps &
      CommonProps & { strategy: typeof RecommendationStrategies.popularity })
  | (UniqueBestsellerProps &
      CommonProps & { strategy: typeof RecommendationStrategies.bestseller });

export type RecommendationStrategy =
  (typeof RecommendationStrategies)[keyof typeof RecommendationStrategies];

export const useUniqueProps = (
  props: HandlerProps
): UseUniquePropsReturn | null => {
  const isUpsale = props.strategy === "upsale";
  const isPersonalized = props.strategy === "personalized";
  const isPopularity = props.strategy === "popularity";
  const isBestseller = props.strategy === "bestseller";
  const isCart = props.strategy === "cart";

  return {
    brands:
      (isPersonalized || isBestseller || isPopularity) &&
      props.brands !== undefined
        ? props.brands
        : null,
    campaigns:
      (isPersonalized || isBestseller || isPopularity) &&
      props.campaigns !== undefined
        ? props.campaigns
        : null,
    categories:
      (isBestseller || isPersonalized || isPopularity || isUpsale
        ? props.categories
        : null) ?? null,
    productIdsInCart: isCart ? props.productIdsInCart : null,
    includedProducts:
      (isPersonalized || isBestseller || isPopularity) &&
      props.includedProducts !== undefined
        ? props.includedProducts
        : null,
    styleId: (isUpsale ? props.styleId : null) ?? null,
  };
};

type Colors = {
  background: string;
  heading: string;
};

const backgroundColorToFontColor = (key?: string) => {
  switch (key) {
    case colors.white.hex:
      return colors.webBlack.hex;
    case colors.lightGray.hex:
      return colors.webBlack.hex;
    case colors.green.hex:
      return colors.darkGreen.hex;
    case colors.lightBlue.hex:
      return colors.blue.hex;
    case colors.lightTurquoise.hex:
      return colors.darkTurquoise.hex;
    case colors.lightAmber.hex:
      return colors.darkAmber.hex;
    case colors.lightOrange.hex:
      return colors.darkOrange.hex;
    default:
      return null;
  }
};

export const getColors = (
  strategy: RecommendationStrategy,
  selectedBackgroundColor?: string
): Colors => {
  switch (strategy) {
    case "upsale":
      return {
        background: hexToRGB(
          selectedBackgroundColor ?? colors.lightBlue.hex,
          0.4
        ),
        heading:
          backgroundColorToFontColor(selectedBackgroundColor) ??
          colors.blue.hex,
      };
    case "recently":
      return {
        background: hexToRGB(
          selectedBackgroundColor ?? colors.lightOrange.hex,
          0.4
        ),
        heading:
          backgroundColorToFontColor(selectedBackgroundColor) ??
          colors.darkOrange.hex,
      };
    case "frequently-bought-together":
      return {
        background: hexToRGB(
          selectedBackgroundColor ?? colors.lightAmber.hex,
          0.4
        ),
        heading:
          backgroundColorToFontColor(selectedBackgroundColor) ??
          colors.darkAmber.hex,
      };
    case "personalized":
      return {
        background: hexToRGB(
          selectedBackgroundColor ?? colors.lightTurquoise.hex,
          0.4
        ),
        heading:
          backgroundColorToFontColor(selectedBackgroundColor) ??
          colors.darkTurquoise.hex,
      };
    case "bestseller":
      return {
        background: hexToRGB(
          selectedBackgroundColor ?? colors.lightGreen.hex,
          0.4
        ),
        heading:
          backgroundColorToFontColor(selectedBackgroundColor) ??
          colors.darkGreen.hex,
      };
    case "popularity":
      return {
        background: hexToRGB(
          selectedBackgroundColor ?? colors.lightGreen.hex,
          0.4
        ),
        heading:
          backgroundColorToFontColor(selectedBackgroundColor) ??
          colors.darkGreen.hex,
      };
    default:
      return {
        background: selectedBackgroundColor ?? colors.white.hex,
        heading:
          backgroundColorToFontColor(selectedBackgroundColor) ??
          colors.black.hex,
      };
  }
};

export const getTitle = (
  strategy: RecommendationStrategy,
  t: Translate
): string | undefined => {
  switch (strategy) {
    case "cart":
      return t("carousel.personalized.title");
    case "upsale":
      return t("carousel.upsale.title");
    case "recently":
      return t("carousel.recently.viewed.title");
    case "personalized":
      return t("carousel.personalized.title");
    case "frequently-bought-together":
      return t("carousel.frequently.bought.together.title");
    default:
      return;
  }
};
