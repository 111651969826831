import pickBy from "lodash/pickBy";
import {
  type BooleanToggles,
  type EnvironmentToggles,
  type KlarnaPlacementWidgetConfig,
  type StringToggles,
} from "react-app/src/global";
import type { NextJsTranslations } from "react-app/src/utils/xxl-translate";
import type { EnvironmentData } from "../global";
import { fetchTranslations } from "../utils/apis/content-translations-api";
import fallbackTranslationsJson from "../utils/translations/fallback.json";
import { getEnvVar } from "./environment-variables";
import { getDynamicTogglesKeyValuePairs } from "./ssm-parameters";
import type {
  GetEnvironmentDataProps,
  GetMergedTranslationsProps,
} from "./types";

const TEAMSALES_TRANSLATION_PREFIX = "teamsales.";

// eslint-disable-next-line import/no-unused-modules -- used in tests
export const getMergedTranslations = ({
  contentApiTranslations,
  fallbackTranslations,
  isTeamsales,
}: GetMergedTranslationsProps) => {
  const translations = {
    ...fallbackTranslations,
    ...contentApiTranslations,
  };
  if (isTeamsales) {
    return translations;
  }
  return pickBy(
    translations,
    (_, key) => !key.startsWith(TEAMSALES_TRANSLATION_PREFIX)
  );
};

export const getTranslationsContent = async (
  isTeamsales: boolean
): Promise<NextJsTranslations> => {
  const contentApiTranslations = await fetchTranslations();
  const fallbackTranslations = fallbackTranslationsJson as NextJsTranslations;
  return getMergedTranslations({
    contentApiTranslations,
    fallbackTranslations,
    isTeamsales,
  });
};

const getFrontendServerUrl = (isDevelopment: boolean) => {
  const hostName = process.env.ENV_DOMAIN_NAME;
  return isDevelopment
    ? `https://localhost.${hostName.replace("www.", "")}`
    : `https://${hostName}`;
};

const getKlarnaPlacementWidget = (): KlarnaPlacementWidgetConfig => {
  return {
    show: getEnvVar("KLARNA_PLACEMENT_WIDGET_SHOW") === "true",
    scriptUrl: getEnvVar("KLARNA_PLACEMENT_WIDGET_SCRIPTURL"),
    clientId: getEnvVar("KLARNA_PLACEMENT_WIDGET_CLIENTID"),
    locale: getEnvVar("KLARNA_PLACEMENT_WIDGET_LOCALE"),
  };
};

export const getGraphQlApiKey = () => process.env.APPSYNC_API_KEY;

export const getGraphQlEndpoint = (isLocal: boolean) =>
  `${getFrontendServerUrl(isLocal)}/graphql`;

const namesOfBooleanToggles = [
  "checkout_psp_walley",
  "giftcards_psp_walley",
  "return_replacement_psp_walley",
  "toggle_cacheable_campaign_search_query",
  "toggle_campaign_page",
  "toggle_christmas_mode",
  "toggle_click_and_collect",
  "toggle_click_and_collect_KCO",
  "toggle_click_and_collect_for_goods_only_in_store",
  "toggle_click_and_collect_quantity",
  "toggle_complete_profile_points",
  "toggle_cross_sales",
  "toggle_delivery_widget",
  "toggle_disable_checkout_coupons",
  "toggle_elevate_categories",
  "toggle_elevate_cluster_landing_page",
  "toggle_elevate_cluster_search",
  "toggle_elevate_favorites",
  "toggle_elevate_pdp",
  "toggle_elevate_quickshop_pdp",
  "toggle_elevate_search",
  "toggle_exit_austria",
  "toggle_fitstation",
  "toggle_free_delivery_widget",
  "toggle_ga_ssr",
  "toggle_kindly_chatbot",
  "toggle_klarna_osm_configuration_externalized",
  "toggle_left_column_page_filters",
  "toggle_log_rocket",
  "toggle_lowest_price",
  "toggle_new_guides",
  "toggle_otp_login",
  "toggle_paperless_returns",
  "toggle_personal_shoewall",
  "toggle_plp_buying_guides",
  "toggle_plp_one_card_content_component",
  "toggle_prisjakt",
  "toggle_products_as_package_quantity",
  "toggle_quick_shop",
  "toggle_rate_and_review_section",
  "toggle_ratings_and_reviews",
  "toggle_react_cat_search",
  "toggle_return_fee",
  "toggle_returns_digital_only",
  "toggle_reward_page",
  "toggle_rewards",
  "toggle_seo_product_archival_page",
  "toggle_social_security_number",
  "toggle_sticky_filter",
  "toggle_triggerbee",
  "toggle_videoly",
  "toggle_vipps_signup",
  "toggle_challenges",
  "toggle_employee_discount",
] as const;

const namesOfStringToggles = [
  "toggle_force_solr_as_provider_on_pages",
  "toggle_eco_online_on_category_codes",
] as const;

export const getEnvironmentData = async ({
  cookieVersion,
  isDevelopment,
  environmentVariables,
  siteUid,
}: GetEnvironmentDataProps): Promise<Omit<EnvironmentData, "pageType">> => {
  const frontEndServerUrl = getFrontendServerUrl(isDevelopment);
  const frontendApi = {
    basePath: frontEndServerUrl,
  };

  const getIsToggleEnabledResult: BooleanToggles & StringToggles =
    await getDynamicTogglesKeyValuePairs({
      siteUid,
      namesOfBooleanToggles,
      namesOfStringToggles,
    });

  const environmentToggles: EnvironmentToggles = {
    fitstation: environmentVariables.TOGGLE_FITSTATION,
  };

  const featureToggles = {
    ...(Object.fromEntries(
      namesOfBooleanToggles.map((toggleName) => [
        toggleName,
        (getIsToggleEnabledResult as BooleanToggles)[toggleName],
      ])
    ) as BooleanToggles),
    ...(Object.fromEntries(
      namesOfStringToggles.map((toggleName) => [
        toggleName,
        (getIsToggleEnabledResult as StringToggles)[toggleName],
      ])
    ) as StringToggles),
    ...environmentToggles,
  };
  return {
    captcha: {
      siteKey: environmentVariables.RECAPTCHA_SITEKEY,
    },
    configuration: {
      contentApi: {
        basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_CONTENT}`,
      },
      customersApi: {
        basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_CUSTOMERS}`,
      },
      deliveryStreamApi: {
        basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_DELIVERYSTREAM}`,
      },
      elevateApi: {
        baseURL: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_ELEVATE}`,
      },
      elevateClusterApi: {
        baseURL: `${process.env.ELEVATE_BASE_URL}/`,
      },
      frontendApi,
      paymentsApi: {
        basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_PAYMENTS}`,
      },
      pimApi: {
        basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_PIM}`,
      },
      productConfiguratorApi: {
        basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_PRODUCT_CONFIGURATOR}`,
      },
      recommendationsApi: {
        basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_RECOMMENDATIONS}`,
      },
      searchApi: {
        basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_SEARCH}`,
      },
      shipmentsApi: {
        basePath: `${process.env.APIS_BASE_URL}/${process.env.NEXT_PUBLIC_API_PATH_SHIPMENTS}`,
      },
      amplifyConfig: {
        aws_appsync_graphqlEndpoint: "/graphql",
        aws_project_region: "",
        aws_appsync_authenticationType: "",
        aws_appsync_apiKey: getGraphQlApiKey(),
        aws_appsync_region: "",
        Auth: {
          region: "",
          userPoolId: "",
          userPoolWebClientId: "",
          mandatorySignIn: false,
          authenticationFlowType: "",
        },
      },
      campaignHubPath: environmentVariables.REQUEST_MAPPING_CAMPAIGNHUBPAGE,
      guidesInterfix: environmentVariables.REQUEST_MAPPING_GUIDES_INTERFIX,
    },
    cookieVersion,
    featureToggles,
    frontEndServerUrl,
    gtmId: environmentVariables.CONFIG_SITE_GTMID,
    isTeamsales: environmentVariables.TOGGLE_TEAMSALES,
    kindlyChatbotKey: environmentVariables.CONFIG_SITE_KINDLYCHATBOTKEY,
    kindlyChatbotLanguage:
      environmentVariables.CONFIG_SITE_KINDLYCHATBOTLANGUAGE,
    klarnaLanguage: environmentVariables.CONFIG_SITE_KLARNALANGUAGE,
    klarnaPlacementWidget: getKlarnaPlacementWidget(),
    language: environmentVariables.CONFIG_SITE_LANGUAGES,
    requestMapping: {
      brands: environmentVariables.REQUEST_MAPPING_BRAND_INDEX,
      campaignHubPage: environmentVariables.REQUEST_MAPPING_CAMPAIGNHUBPAGE,
      clubAssortment: "/club/{0}/assortment", // same as hard coded value in script.tag
      customerService: `/${environmentVariables.REQUEST_MAPPING_CUSTOMERSERVICE}`,
      faq: environmentVariables.REQUEST_MAPPING_FAQ,
      faqGiftCard: environmentVariables.REQUEST_MAPPING_FAQGIFTCARD,
      giftCard: environmentVariables.REQUEST_MAPPING_BUYGIFTCARD,
      guides: environmentVariables.REQUEST_MAPPING_GUIDES,
      account: environmentVariables.REQUEST_MAPPING_MY_ACCOUNT,
      login: environmentVariables.REQUEST_MAPPING_LOGIN,
      outlet: environmentVariables.REQUEST_MAPPING_OUTLET,
      challenges: environmentVariables.REQUEST_MAPPING_CHALLENGES,
      myAccount: {
        challenges: environmentVariables.REQUEST_MAPPING_MY_ACCOUNT_CHALLENGES,
        favorites: environmentVariables.REQUEST_MAPPING_MY_ACCOUNT_FAVORITES,
        fitstation: environmentVariables.REQUEST_MAPPING_MY_ACCOUNT_FITSTATION,
        orders: environmentVariables.REQUEST_MAPPING_MY_ACCOUNT_ORDERS,
        settings: environmentVariables.REQUEST_MAPPING_MY_ACCOUNT_SETTINGS,
        rewards: environmentVariables.REQUEST_MAPPING_MY_ACCOUNT_REWARDS,
        rateAndReview:
          environmentVariables.REQUEST_MAPPING_MY_ACCOUNT_RATE_AND_REVIEW,
      },
      newsletter: environmentVariables.REQUEST_MAPPING_NEWSLETTER,
      qrReturn: environmentVariables.QR_HOST_PREFIX,
      reward: environmentVariables.REQUEST_MAPPING_REWARD,
      search: environmentVariables.REQUEST_MAPPING_SEARCH,
      shared: environmentVariables.REQUEST_MAPPING_SHARED,
      storeFinder: environmentVariables.REQUEST_MAPPING_STORE_FINDER,
      store: environmentVariables.REQUEST_MAPPING_STORE_PREFIX,
      workshop: environmentVariables.REQUEST_MAPPING_WORKSHOP,
      games: environmentVariables.REQUEST_MAPPING_GAMES,
    },
    rewardSignupPageUrl: `${environmentVariables.REQUEST_MAPPING_REWARD}${environmentVariables.REQUEST_MAPPING_REWARD_SIGNUP}`,
    siteDefaultLanguage: environmentVariables.CONFIG_SITE_LANGUAGES,
    siteCurrency: environmentVariables.CONFIG_SITE_CURRENCY,
    siteCountry: environmentVariables.CONFIG_SITE_COUNTRY,
    siteUid: environmentVariables.CONFIG_SITE_UID,
    symplifyId: environmentVariables.CONFIG_SITE_SYMPLIFYID,
    videolyId: environmentVariables.CONFIG_SITE_VIDEOLYID,
    volumentalId: environmentVariables.CONFIG_SITE_VOLUMENTALID,
    gitHash: environmentVariables.GIT_HASH,
    fupBreachCustomerServicePath:
      environmentVariables.CONFIG_SITE_FUP_BREACH_CUSTOMERSERVICE_PATH,
  };
};
