import React from "react";
import { ArrowButton, IconContainer } from "./Slider.styled";
import { Icon } from "../../Icon";
import noop from "lodash/noop";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  horizontalMargin?: number;
  verticalMargin?: number;
};
const Left = ({
  disabled = false,
  onClick = noop,
  horizontalMargin = -44,
  verticalMargin,
}: Props) => {
  const { t } = useTranslations();
  return (
    <ArrowButton
      data-testid="slider-arrow-left"
      disabled={disabled}
      onClick={onClick}
      aria-disabled={disabled}
      aria-label={t("carousel.previous.product")}
      style={{
        left: `${horizontalMargin}px`,
        bottom: `${
          verticalMargin !== undefined ? `${verticalMargin}px` : "auto"
        }`,
        top: `${verticalMargin !== undefined ? "auto" : "50%"}`,
      }}
    >
      <IconContainer>
        <Icon name="CaretLeft" />
      </IconContainer>
    </ArrowButton>
  );
};

const Right = ({
  disabled = false,
  onClick = noop,
  horizontalMargin = -44,
  verticalMargin,
}: Props) => {
  const { t } = useTranslations();
  return (
    <ArrowButton
      data-testid="slider-arrow-right"
      disabled={disabled}
      onClick={onClick}
      aria-disabled={disabled}
      aria-label={t("carousel.next.product")}
      style={{
        right: `${horizontalMargin}px`,
        left: "auto",
        bottom: `${
          verticalMargin !== undefined ? `${verticalMargin}px` : "auto"
        }`,
        top: `${verticalMargin !== undefined ? "auto" : "50%"}`,
      }}
    >
      <IconContainer>
        <Icon name="CaretRight" />
      </IconContainer>
    </ArrowButton>
  );
};

export const Arrow = {
  Left,
  Right,
};
