import { hasNoValue, hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import { Check } from "@xxl/icons";
import noop from "lodash/noop";
import React, { useCallback, useEffect, useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { useInView } from "react-intersection-observer";
import { QUANTITY_ONE } from "../../constants";
import { useApiClients } from "../../contexts/ApiClients";
import { useSharedData } from "../../contexts/SharedData";
import { useTracking } from "../../contexts/Tracking";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { useElevateRequestData } from "../../hooks/useElevateRequestData/useElevateRequestData";
import { toProductCardDataFromBase } from "../../utils/ProductData/product-card-data-helper";
import {
  addToCart,
  ERROR_RESPONSE_STATUS,
  SUCCESS_RESPONSE_STATUS,
} from "../Cart/Api/CartAPI";
import type { UpdateQuantityGraphQLError } from "../Cart/Api/types";
import { UPDATE_CART_COUNT, useCartContext } from "../Cart/CartState";
import { SmallProductPrice } from "../Cart/Components/SmallProductPrice";
import { ErrorsList } from "../Cart/Styles/CartContent.styled";
import {
  OutOfStockHeading,
  OutOfStockListItem,
  OutOfStockOverlay,
  ProductImage,
} from "../Cart/Styles/ProductItem.styled";
import type { TrackProductImpressionProps } from "../PersonalizedProductList/tracking-helper";
import { trackProductImpression } from "../PersonalizedProductList/tracking-helper";
import { trackProductClick } from "../Product/product-helper";
import { CROSS_SALES_PERSONALIZED_KEY } from "./constants";
import {
  productCardDataToCrossSalesProduct,
  type CrossSalesProduct as CSP,
  type Size,
  type Style,
} from "./CrossSales.helper";
import {
  BrandAndName,
  Column,
  CrossSalesOptionsToggleButton,
  CrossSalesProductActionsWrapper,
  CrossSalesProductBrand,
  CrossSalesProductLink,
  CrossSalesProductWrapper,
  Row,
} from "./CrossSales.styled";
import { CrossSalesAddToCartButton } from "./CrossSalesAddToCartButton";
import { CrossSalesOptionsSelector } from "./CrossSalesOptionsSelector";
import { CrossSalesProductColorAndSize } from "./CrossSalesProductColorAndSize";
import {
  getPreselectedSize,
  getPreselectedStyle,
  getPriceString,
  getPrimaryImageLink,
  getProductLinkWithTrackingListName,
  getSizeStockStatus,
} from "./utils";

type CrossSalesProductProps = {
  product: CSP;
  idx: number;
  onProductAddedToCart?: () => void;
};

export const CrossSalesProduct: React.FC<CrossSalesProductProps> = ({
  product,
  idx,
  onProductAddedToCart = noop,
}) => {
  const { t } = useTranslations();
  const { dispatch: cartDispatch } = useCartContext();
  const { pageType, configuration } = useSharedData().data;
  const { elevateApi } = useApiClients();
  const { getBaseQuery } = useElevateRequestData();

  const trackers = useTracking();

  const trackingListName = `${pageType}_${CROSS_SALES_PERSONALIZED_KEY}`; // ATM it's always "product_cross-sales"

  const [addToCartError, setAddToCartError] = useState<string | undefined>();
  const [isAddToCartDisabled, setIsAddToCartDisabled] =
    useState<boolean>(false);
  const [isAddToCartLoading, setIsAddToCartLoading] = useState<boolean>(false);
  const [isAddToCartSuccess, setIsAddToCartSuccess] = useState<boolean>(false);
  const [isFullyConfigured, setIsFullyConfigured] = useState<boolean>(false);
  const [isOptSelectActive, setIsOptSelectActive] = useState<boolean>(false);
  const [isSizeLoading, setIsSizesLoading] = useState<boolean>(false);
  const [sizeOptions, setSizeOptions] = useState<Size[]>(
    product.sizeOptions ?? []
  );
  const [selectedSize, setSelectedSize] = useState<Size | undefined>();
  const [selectedStyle, setSelectedStyle] = useState<Style | undefined>();
  const [styleOptions, setStyleOptions] = useState<Style[]>([]);
  const [productUrl, setProductUrl] = useState<string | undefined>(product.url);
  const [hasMultipleOptions, setHasMultipleOptions] = useState<boolean>(
    styleOptions.length > 1 || sizeOptions.length > 1
  );
  const [productPrimaryImage, setProductPrimaryImage] = useState<
    string | undefined
  >(product.primaryImage);
  const [productPrice, setProductPrice] = useState<
    CrossSalesProductProps["product"]["price"]
  >(product.price);
  const [productLink, setProductLink] = useState<string>(
    getProductLinkWithTrackingListName({
      selectedStyleUrl: selectedStyle?.url,
      productUrl,
      gtmProductListName: trackingListName,
    })
  );
  const [productImageLink, setProductImageLink] = useState<string>(
    getPrimaryImageLink(selectedStyle?.primaryImage, product.primaryImage)
  );

  const handleProductImpressionTracking = useCallback(
    ({
      listName,
      position,
      product: trackingProduct,
    }: TrackProductImpressionProps) =>
      (inView: boolean) =>
        inView &&
        trackProductImpression({
          listName,
          position,
          product: {
            brandName: trackingProduct.brandName,
            code: trackingProduct.code,
            googleCategory:
              "googleCategory" in trackingProduct
                ? trackingProduct.googleCategory
                : undefined,
            name: trackingProduct.name,
            style: trackingProduct.style,
            salesPrice: trackingProduct.salesPrice,
          },
          trackers,
        }),
    []
  );

  const { ref: inViewRef } = useInView({
    triggerOnce: true,
    threshold: 0.5,
    onChange: handleProductImpressionTracking({
      listName: trackingListName,
      position: idx,
      product,
      trackers,
    }),
  });

  const toggleOptionsSelector = useCallback(() => {
    setIsOptSelectActive(!isOptSelectActive);
  }, [isOptSelectActive]);

  const handleProductAddError = useCallback(
    (isOutOfStockError = false) => {
      setAddToCartError(
        isOutOfStockError
          ? t("product.details.add.to.cart.out.of.stock")
          : t("product.details.add.to.cart.failure")
      );
    },
    [setAddToCartError, t]
  );

  const handleProductAddSuccess = useCallback(() => {
    const ean =
      selectedSize?.ean ?? selectedStyle?.code ?? (product.ean ?? [])[0];
    const ticket =
      selectedSize?.ticket ?? selectedStyle?.ticket ?? product.ticket;
    if (hasValue(product.name) && hasValue(ean)) {
      trackers.sendAddToCartEvent({
        product: {
          brand: product.brand?.name,
          category: product.categoryName,
          id: product.code,
          name: product.name,
          price: getPriceString(product.price),
          priceType: product.price.type ?? "",
        },
        list: trackingListName,
        deliveryStreamProduct: {
          id: product.code,
          size: selectedSize?.code ?? "",
          ean,
          entryNumber: 0,
          brand: product.brand?.name ?? "",
          productPrice: product.price.salesPrice,
        },
        ticket,
      });
    }

    cartDispatch({ type: UPDATE_CART_COUNT });
    setIsOptSelectActive(false);
    setIsAddToCartSuccess(true);
    onProductAddedToCart();
  }, [
    cartDispatch,
    onProductAddedToCart,
    product.brand?.name,
    product.categoryName,
    product.code,
    product.ean,
    product.name,
    product.price,
    product.ticket,
    selectedSize?.code,
    selectedSize?.ean,
    selectedSize?.ticket,
    selectedStyle?.code,
    selectedStyle?.ticket,
    trackers,
    trackingListName,
  ]);

  const updateSizes = useCallback(
    async (styleCode: string) => {
      try {
        setSelectedSize(undefined);
        setSizeOptions([]);
        setIsSizesLoading(true);

        const response = await elevateApi.storefront.productPage(
          {
            ...getBaseQuery(),
            productKey: styleCode,
            channels: "ONLINE|STORE",
          },
          {}
        );

        const { baseProduct } = response.data;

        if (hasNoValue(baseProduct)) {
          throw Error("No base product found.");
        }

        const product = toProductCardDataFromBase(baseProduct);
        const { sizeOptions } = productCardDataToCrossSalesProduct(product);

        if (hasNoValue(sizeOptions)) {
          return;
        }

        setSizeOptions(sizeOptions);
        setSelectedSize(getPreselectedSize(sizeOptions));
      } catch (_error) {
        setAddToCartError(t("crossSales.error.fetchSizes"));
      } finally {
        setIsSizesLoading(false);
      }
    },
    [t]
  );

  const handleSelectStyle = useCallback(
    (styleData?: Style) => {
      setSelectedStyle(styleData);
      if (
        isNotNullOrUndefined(styleData) &&
        isNotNullOrUndefined(styleData.code)
      ) {
        void updateSizes(styleData.code);
      }
    },
    [setSelectedStyle, updateSizes]
  );

  // initial component configuration
  useEffect(() => {
    setProductUrl(product.url);
    setProductPrimaryImage(product.primaryImage);
    setProductPrice(product.price);
    setStyleOptions(product.styleOptions ?? []);
    handleSelectStyle(getPreselectedStyle(product.styleOptions, product.code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setProductLink(
      getProductLinkWithTrackingListName({
        selectedStyleUrl: selectedStyle?.url,
        productUrl,
        gtmProductListName: trackingListName,
      })
    );
  }, [productUrl, selectedStyle?.url, trackingListName]);

  useEffect(() => {
    setProductImageLink(
      getPrimaryImageLink(selectedStyle?.primaryImage, productPrimaryImage)
    );
  }, [productPrimaryImage, selectedStyle?.primaryImage]);

  useEffect(() => {
    setHasMultipleOptions(styleOptions.length > 1 || sizeOptions.length > 1);
  }, [sizeOptions.length, styleOptions.length]);

  useEffect(() => {
    setAddToCartError(undefined);
    const isCompletelyConfigured =
      !isSizeLoading &&
      (sizeOptions.length <= 1 || selectedSize !== undefined) &&
      (styleOptions.length <= 1 || selectedStyle !== undefined);
    setIsFullyConfigured(isCompletelyConfigured);
  }, [
    isSizeLoading,
    selectedSize,
    selectedStyle,
    setAddToCartError,
    setIsFullyConfigured,
    sizeOptions.length,
    styleOptions.length,
  ]);

  useEffect(() => {
    const isSelectedItemOutOfStock =
      selectedStyle?.stockStatus === "OUTOFSTOCK" ||
      getSizeStockStatus(selectedSize) === "OUTOFSTOCK";
    const disableAddToCart =
      isAddToCartLoading ||
      isSizeLoading ||
      (isOptSelectActive && !isFullyConfigured) ||
      isSelectedItemOutOfStock;
    setIsAddToCartDisabled(disableAddToCart);
  }, [
    isAddToCartLoading,
    isFullyConfigured,
    isOptSelectActive,
    isSizeLoading,
    selectedSize,
    selectedStyle,
    setIsAddToCartDisabled,
  ]);

  const handleAddToCartClick = useCallback(() => {
    const handleAddToCart = async () => {
      const ean = selectedSize?.ean ?? (product.ean ?? [])[0];
      setIsAddToCartLoading(true);
      setIsAddToCartDisabled(true);

      try {
        if (!isNotNullOrUndefined(ean)) throw new Error("No EAN found");
        const egResponse = await addToCart(
          [{ ean, quantity: QUANTITY_ONE }],
          configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
          configuration.amplifyConfig.aws_appsync_apiKey
        );
        switch (egResponse.status) {
          case SUCCESS_RESPONSE_STATUS:
            handleProductAddSuccess();
            break;
          case ERROR_RESPONSE_STATUS:
            {
              const isOutOfStock = (
                egResponse.data.errors as UpdateQuantityGraphQLError[]
              ).some((err) => err.errorType === "OUT_OF_STOCK");
              handleProductAddError(isOutOfStock);
            }
            break;
        }
      } catch (_error) {
        handleProductAddError();
      } finally {
        setIsAddToCartLoading(false);
        setIsAddToCartDisabled(false);
      }
    };

    if (isFullyConfigured) {
      void handleAddToCart();
    } else {
      setIsOptSelectActive(true);
    }
  }, [
    configuration.amplifyConfig.aws_appsync_apiKey,
    configuration.amplifyConfig.aws_appsync_graphqlEndpoint,
    handleProductAddError,
    handleProductAddSuccess,
    isFullyConfigured,
    product.ean,
    selectedSize?.ean,
    selectedStyle?.code,
  ]);

  const handleProductClick = (): void => {
    const ticket =
      selectedSize?.ticket ?? selectedStyle?.ticket ?? product.ticket;
    trackProductClick(
      trackers,
      product,
      {
        pageType: "product",
        position: idx,
        list: CROSS_SALES_PERSONALIZED_KEY,
      },
      trackingListName,
      ticket
    );
  };

  return (
    <CrossSalesProductWrapper
      data-testid={`cross-sales-product-${product.code ?? "missing-code"}`}
      ref={inViewRef}
    >
      <CrossSalesProductLink href={productLink} onClick={handleProductClick}>
        <ProductImage
          data-private={true}
          src={productImageLink}
          alt={product.name}
          size={100}
        />
      </CrossSalesProductLink>
      <Column>
        <Row>
          <BrandAndName>
            {product.brand !== undefined && (
              <CrossSalesProductBrand>
                {product.brand.name}
              </CrossSalesProductBrand>
            )}
            {product.name}
          </BrandAndName>
          <SmallProductPrice
            priceDisplay={productPrice}
            productType={
              "productType" in product ? product.productType ?? null : null
            }
          />
        </Row>
        <CrossSalesProductActionsWrapper
          className={isOptSelectActive ? "popup-open" : ""}
          flexDirection={isOptSelectActive ? "column" : "row"}
        >
          {hasMultipleOptions ? (
            <CrossSalesOptionsToggleButton
              onClick={toggleOptionsSelector}
              isToggled={isOptSelectActive}
            >
              {isOptSelectActive
                ? t("crossSales.options.hide")
                : t("crossSales.options.show")}
            </CrossSalesOptionsToggleButton>
          ) : (
            <CrossSalesProductColorAndSize
              selectedSize={selectedSize}
              selectedStyle={{
                colorName: selectedStyle?.colorName,
              }}
            />
          )}
          {isOptSelectActive && (
            <CrossSalesOptionsSelector
              addToCartError={addToCartError}
              handleSelectStyle={handleSelectStyle}
              isSizeLoading={isSizeLoading}
              selectedSize={selectedSize}
              selectedStyle={selectedStyle}
              setSelectedSize={setSelectedSize}
              sizeOptions={sizeOptions}
              styleOptions={styleOptions}
            />
          )}
          <CrossSalesAddToCartButton
            isDisabled={isAddToCartDisabled}
            isLoading={isAddToCartLoading}
            isSuccess={isAddToCartSuccess}
            onClick={handleAddToCartClick}
            onSuccessAnimationEnd={() => {
              setIsAddToCartSuccess(false);
            }}
          />
        </CrossSalesProductActionsWrapper>
      </Column>
      {((Boolean(addToCartError) && !hasMultipleOptions) ||
        isAddToCartSuccess) && (
        <OutOfStockOverlay>
          <ErrorsList>
            <OutOfStockListItem>
              <OutOfStockHeading>
                {addToCartError ?? (
                  <>
                    {t("cart.notification.item.added")}
                    <Check />
                  </>
                )}
              </OutOfStockHeading>
            </OutOfStockListItem>
          </ErrorsList>
        </OutOfStockOverlay>
      )}
    </CrossSalesProductWrapper>
  );
};
