import { useInView } from "framer-motion";
import { useRef, type PropsWithChildren } from "react";

/**
 * InView component based on framer-motion
 */
const InView = ({
  children,
  onInView,
}: PropsWithChildren & { onInView: () => void }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5, once: true });
  if (isInView) {
    onInView();
  }
  return <div ref={ref}>{children}</div>;
};

export { InView };
