import { isNotNullOrUndefined } from "@xxl/common-utils";
import {
  SanityContent,
  type AutocompleteResultData,
  type BaseProductData,
  type ContentListData,
  type ContentListSettingsSearch,
  type ElevateApi,
  type ImageData,
  type SanityContentType,
  type StoreContentCustomData,
} from "@xxl/product-search-api";
import type { ContentName } from "../../../api/elevate-api";
import { CONTENT_NAMES, getAutoSuggestions } from "../../../api/elevate-api";
import type { EcomSiteUidLegacy } from "../../../global";
import {
  cookieNames,
  getFallbackSessionKey,
  getSessionKey,
  getXXLCookie,
  setCookie,
} from "../../../utils/Cookie";
import { toProductCardDataFromBase } from "../../../utils/ProductData/product-card-data-helper";
import {
  convertSiteUidToHost,
  legacySiteUidToSiteUid,
} from "../../../utils/xxl-shared-data";
import { type AutoCompleteData } from "../SearchBoxState";

export type ContentData = {
  type: SanityContentType;
  title: string;
  description: string;
  image: ImageData[];
  link: string;
  ticket: string;
  custom?: {
    contentCustomAttributes?:
      | Record<string, unknown>[]
      | StoreContentCustomData[];
  };
};

export const getSuggestions = async ({
  apiClient,
  contentLists,
  isLaptop,
  isLoggedIn,
  isTeamsales,
  productRules,
  query,
  siteUid,
}: {
  apiClient: ElevateApi<unknown>;
  contentLists?: ContentListSettingsSearch[];
  isLaptop: boolean;
  isLoggedIn: boolean;
  isTeamsales: boolean;
  productRules?: string;
  query: string;
  siteUid: EcomSiteUidLegacy;
}) => {
  const xxlCookie = await getXXLCookie();
  let sessionKey = getSessionKey();

  if (xxlCookie === null) {
    throw Error("Missing xxl cookie.");
  }

  const { customerKey } = xxlCookie;
  if (customerKey === undefined) {
    throw Error("Missing customerKey cookie.");
  }

  if (sessionKey.length === 0) {
    const uuid = getFallbackSessionKey();
    setCookie(cookieNames.SESSION_KEY, uuid);
    sessionKey = uuid;
  }

  const memberType = isLoggedIn ? "member" : "anonymous";

  return getAutoSuggestions({
    apiClient,
    query: {
      customerKey,
      q: typeof query !== "string" ? "" : query,
      sessionKey,
      site: convertSiteUidToHost(legacySiteUidToSiteUid(siteUid)),
      touchpoint: isLaptop ? "DESKTOP" : "MOBILE",
      priceId: isTeamsales ? "teamsales" : memberType,
      channels: "ONLINE|STORE",
    },
    productRules,
    contentLists,
  });
};

const toQueries = (
  suggestions: AutocompleteResultData["phraseSuggestions"]
): AutoCompleteData["queries"] => ({
  count: suggestions?.length ?? 0,
  items: suggestions
    ?.filter(({ q }) => isNotNullOrUndefined(q))
    .map(({ q }) => ({
      query: q ?? "",
    })),
});

const getItemsData = (
  contentLists: ContentListData[],
  filterKey: ContentName
): {
  count: number;
  items: ContentData[];
} => {
  const [firstList] = contentLists.filter(({ id }) => id === filterKey);
  const { items } = firstList;

  return {
    count: items.length,
    items: items,
  };
};

const toProductResults = (baseProductData: BaseProductData) => {
  const { averageRating, brandName, name, url, price, primaryImage, ticket } =
    toProductCardDataFromBase(baseProductData);

  return {
    averageRating,
    ...(isNotNullOrUndefined(brandName) && {
      brand: {
        name: brandName,
      },
    }),
    name,
    url,
    price,
    primaryImage,
    ticket,
  };
};

export const toAutoCompleteData = (
  data: AutocompleteResultData,
  query: string
): AutoCompleteData => {
  const { phraseSuggestions = [], productSuggestions, contentLists } = data;

  return {
    brandResults: getItemsData(contentLists, CONTENT_NAMES.brand),
    campaignHubResults: getItemsData(
      contentLists,
      CONTENT_NAMES[SanityContent.CAMPAIGN]
    ),
    categoryResults: getItemsData(contentLists, CONTENT_NAMES.category),
    faqHubResults: getItemsData(contentLists, CONTENT_NAMES[SanityContent.FAQ]),
    guideResults: getItemsData(contentLists, CONTENT_NAMES.guide),
    storeResults: getItemsData(contentLists, CONTENT_NAMES.store),
    queries: toQueries(phraseSuggestions),
    queryString: query,
    productResults: {
      items: productSuggestions.map(toProductResults).map((product) => ({
        ...product,
        ...{
          version: 2,
        },
      })),
    },
  };
};
