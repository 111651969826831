import styled from "@emotion/styled/macro";
import { css } from "@emotion/react";
import { xxlTheme } from "../../styles/xxl-theme";
import { typographyToCss, MQ } from "../../styles/helpers";
import {
  LAPTOP_REWARDS_SECTION_WIDTH,
  MOBILE_REWARDS_SECTION_WIDTH,
  TABLET_REWARDS_SECTION_WIDTH,
} from "./BonusPoints.styled";
import { Info } from "@xxl/icons";

const { spaces, colors } = xxlTheme;

export const typography = {
  expirationDate: {
    fontSize: 10,
    fontFamily: "var(--font-family-medium)",
    lineHeight: 1.6,
    letterSpacing: -0.15,
  },
  ribbon: {
    fontSize: 12,
    fontFamily: "var(--font-family-medium)",
    lineHeight: 1.4,
    letterSpacing: -0.15,
  },
  ribbonInDescription: {
    fontSize: 20,
    fontFamily: "var(--font-family-medium)",
    lineHeight: 1.4,
    letterSpacing: -0.15,
  },
  heading: {
    fontSize: 20,
    fontFamily: "var(--font-family-bold)",
    lineHeight: 1.1,
    letterSpacing: -0.15,
    laptop: {
      fontSize: 24,
    },
  },
  headingInDescription: {
    fontSize: 34,
    fontFamily: "var(--font-family-bold)",
    lineHeight: 1.1,
    letterSpacing: -0.15,
  },
  readMoreHeading: {
    fontSize: 36,
    fontFamily: "var(--font-family-bold)",
    lineHeight: 1.1,
    letterSpacing: -0.15,
  },
  subheading: {
    fontSize: 12,
    fontFamily: "var(--font-family-regular)",
    lineHeight: 1.3,
  },
  sliderHeading: {
    fontSize: 24,
    fontFamily: "var(--font-family-bold)",
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  sliderDescription: {
    fontSize: 15,
    fontFamily: "var(--font-family-regular)",
    lineHeight: 1.2,
  },
  readMoreSubheading: {
    fontSize: 15,
    fontFamily: "var(--font-family-medium)",
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  readMoreDescription: {
    fontSize: 15,
    fontFamily: "var(--font-family-regular)",
    lineHeight: 1.2,
    letterSpacing: -0.15,
  },
  subheadingInDescription: {
    fontSize: 20,
    fontFamily: "var(--font-family-regular)",
    lineHeight: 1.1,
  },
};

const {
  expirationDate,
  ribbon,
  heading,
  subheading,
  ribbonInDescription,
  headingInDescription,
  subheadingInDescription,
  readMoreHeading,
  readMoreSubheading,
  readMoreDescription,
  sliderHeading,
  sliderDescription,
} = typography;

type DescriptionTextProps = {
  isDescriptionText?: boolean;
  isBonusCheck: boolean;
  isTruncated?: boolean;
};

type OfferItemProps = {
  hasReadMore: boolean;
  isReadMoreOpened: boolean;
};

export const OfferItem = styled.article<OfferItemProps>(
  ({ hasReadMore, isReadMoreOpened }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    overflow: hidden;

    ${MQ("desktop")} {
      max-width: 190px;
    }

    & > svg {
      position: absolute;
      bottom: -4px;
      left: 0;
      z-index: 2;
      width: 100%;
      color: ${colors.xxlWhite};
    }

    ${hasReadMore &&
    css`
      &:hover {
        cursor: ${isReadMoreOpened ? "unset" : "pointer"};
      }
    `}
  `
);

export const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & * {
    box-sizing: border-box;
  }
`;

type DescriptionProps = {
  isVisible: boolean;
  image: string | null;
};

export const DescriptionBody = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
`;

const setBackgroundColor = (
  isVisible: boolean,
  image: string | null
): string => {
  if (!isVisible && image === null) {
    return colors.xxlWebBlack;
  } else if (isVisible) {
    return colors.xxlLightGrey;
  }
  return colors.xxlWhite;
};

export const ContentWrapper = styled.div<DescriptionProps>(
  ({ isVisible, image }) => css`
    display: flex;
    width: 100%;
    height: 100%;
    margin-bottom: ${spaces.smallRegular};
    flex-direction: column;
    background-color: ${setBackgroundColor(isVisible, image)};
    background-image: ${!isVisible && image !== null
      ? `url(${image})`
      : "none"};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: ${isVisible ? colors.xxlWebBlack : colors.xxlWhite};
    position: relative;

    ${!isVisible &&
    image !== null &&
    css`
      &::before {
        content: "";
        display: block;
        position: absolute;
        inset: 0;
        background-color: ${colors.xxlWebBlack};
        opacity: 0.3;
      }
    `}
  `
);

export const ShowMoreButton = styled.button`
  margin-top: auto;
  margin-bottom: ${spaces.smallRegular};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${MOBILE_REWARDS_SECTION_WIDTH};
  margin-top: ${spaces.large};

  & .arrow-left,
  & .arrow-right {
    display: none;
  }

  ${MQ("tablet")} {
    max-width: ${TABLET_REWARDS_SECTION_WIDTH};
  }

  ${MQ("laptop")} {
    max-width: ${LAPTOP_REWARDS_SECTION_WIDTH};
  }

  ${MQ("bigDesktop")} {
    max-width: none;
    & .arrow-left {
      display: flex;
      left: -45px;
    }

    & .arrow-right {
      display: flex;
      right: -45px;
    }
  }
`;

export const OfferBody = styled.div`
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${MQ("laptop")} {
    height: 240px;
  }
`;

type BackgroundProps = {
  image: string | null;
};

export const OfferBackground = styled.div<BackgroundProps>(
  ({ image }) => css`
    background-color: ${colors.xxlWebBlack};
    position: relative;
    padding: ${spaces.smallRegular};
    height: 100%;
    box-sizing: border-box;

    ${image !== null &&
    css`
      background-image: url(${image});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    `}

    &::before {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      background-color: ${colors.xxlWebBlack};
      opacity: 0.3;
    }
  `
);

export const BonusCheckLineWrapper = styled.span`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 110%;
  height: 24px;
  left: -5%;
  overflow: hidden;
`;

export const BonusCheckDiamond = styled.span`
  display: block;
  height: 100%;
  width: 14%;
  background-color: ${colors.xxlGreen};
  transform: skew(-25deg);
`;

export const Heading = styled.h3<DescriptionTextProps>(
  ({ isDescriptionText = false, isBonusCheck, isTruncated = false }) => css`
    color: ${isBonusCheck ? colors.xxlGreen : colors.xxlWhite};
    position: relative;
    z-index: 2;
    ${typographyToCss(isDescriptionText ? headingInDescription : heading)};
    margin: ${spaces.smallRegular} 0;
    word-break: break-word;

    ${isDescriptionText &&
    css`
      margin: 0 ${spaces.regular} ${spaces.smallRegular};
    `}

    ${isTruncated &&
    css`
      position: relative;
      display: block;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    `}
  `
);

export const Subheading = styled.p<DescriptionTextProps>(
  ({ isDescriptionText = false, isBonusCheck, isTruncated = false }) => css`
    margin: 0 0 ${spaces.smallRegular};
    color: ${isBonusCheck ? colors.xxlGreen : colors.xxlWhite};
    position: relative;
    z-index: 2;
    ${typographyToCss(
      isDescriptionText ? subheadingInDescription : subheading
    )};
    word-break: break-word;

    ${isDescriptionText &&
    css`
      margin: ${spaces.smallRegular} ${spaces.regular};
    `}

    ${isTruncated &&
    css`
      position: relative;
      display: block;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
    `}
  `
);

export const OfferType = styled.p<DescriptionTextProps>(
  ({ isDescriptionText }) => css`
    margin: 0;
    color: ${colors.xxlWhite};
    position: relative;
    z-index: 2;
    ${typographyToCss(
      isDescriptionText === true ? ribbonInDescription : ribbon
    )};
    display: flex;
    align-items: center;

    ${isDescriptionText === true &&
    css`
      padding: ${spaces.regular};
    `}
  `
);

type MobileFooterProps = {
  isVisible: boolean;
  isDescriptionText: boolean;
};

export const OfferFooter = styled.div<MobileFooterProps>(
  ({ isVisible, isDescriptionText }) => css`
    width: 100%;
    padding: ${spaces.smallRegular} ${spaces.mini};
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${isDescriptionText && isVisible
      ? colors.xxlMediumGrey
      : colors.xxlWebGrey};
    box-sizing: border-box;

    ${isDescriptionText &&
    css`
      margin-top: auto;
      position: relative;
      z-index: 2;
    `}
  `
);

export const InfoIcon = styled(Info)`
  width: 20px;
  height: 20px;
  margin-left: ${spaces.mini};
`;

export const OfferExpiration = styled.p<MobileFooterProps>(
  ({ isVisible, isDescriptionText }) => css`
    color: ${isDescriptionText && isVisible
      ? colors.xxlWebBlack
      : colors.xxlGreen};
    margin: 0;
    ${typographyToCss(expirationDate)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  `
);

export const ExpirationWrapper = styled.span`
  display: flex;
  align-items: center;

  & > svg {
    width: 15px;
    height: 15px;
    margin: 0 ${spaces.micro} 0 0;
  }
`;

export const ExpirationText = styled.span<MobileFooterProps>(
  ({ isDescriptionText }) => css`
    ${!isDescriptionText &&
    css`
      position: relative;
      display: block;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    `}
  `
);

export const ReadMoreHeading = styled.h2`
  ${typographyToCss(readMoreHeading)};
  margin: ${spaces.regular} ${spaces.regular} ${spaces.smallRegular};
`;

export const ReadMoreSubheading = styled.p`
  ${typographyToCss(readMoreSubheading)};
  margin: ${spaces.smallRegular} ${spaces.regular};
`;

export const ReadMoreDescription = styled.p`
  ${typographyToCss(readMoreDescription)};
  margin: ${spaces.smallRegular} ${spaces.regular};
`;

export const SliderHeading = styled.h2`
  ${typographyToCss(sliderHeading)};
  margin: 0 0 ${spaces.smallRegular};
`;

export const SliderDescription = styled.p`
  ${typographyToCss(sliderDescription)};
  margin: 0 0 ${spaces.large};
`;

export const ProductsCarouselWrapper = styled.section`
  max-width: ${MOBILE_REWARDS_SECTION_WIDTH};

  ${MQ("tablet")} {
    max-width: ${TABLET_REWARDS_SECTION_WIDTH};

    & .arrow-left {
      left: ${spaces.micro};
    }

    & .arrow-right {
      right: ${spaces.micro};
    }
  }

  ${MQ("laptop")} {
    max-width: ${LAPTOP_REWARDS_SECTION_WIDTH};
  }

  ${MQ("desktop")} {
    & .arrow-left {
      left: -45px;
    }

    & .arrow-right {
      right: -45px;
    }
  }

  ${MQ("bigDesktop")} {
    max-width: none;
  }
`;
