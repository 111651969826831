import { RecommendationStrategies } from "@/components/PersonalizedProductLists/Handler/Handler.helper";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { Icon } from "@/react-components/Icon";
import { CTAContainer, Link } from "./UpsaleLinkComponent.styled";

export const UpsaleLinkComponent = () => {
  const { t } = useTranslations();
  return (
    <Link href={`#${RecommendationStrategies.upsale}`}>
      <CTAContainer>
        <span>{t("general.see.similar.products")}</span>
        <Icon name={"ArrowRight"} size="inherit" />
      </CTAContainer>
      <Icon name={"Xxl"} size={"inherit"} />
    </Link>
  );
};
