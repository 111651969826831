import { QueryClientProvider as QCP, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { PropsWithChildren } from "react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
  },
});

export const QueryClientProvider = ({ children }: PropsWithChildren) => (
  <QCP client={queryClient}>
    {children}
    <ReactQueryDevtools initialIsOpen={false} />
  </QCP>
);
