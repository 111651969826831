import { RecommendationStrategies } from "@/components/PersonalizedProductLists/Handler/Handler.helper";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { XxlButton } from "@/react-components/Common/XxlButton";
import { Icon } from "@/react-components/Icon";

export const FormButton = () => {
  const { t } = useTranslations();
  const handleClick = () =>
    document
      .getElementById(RecommendationStrategies.upsale)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  return (
    <XxlButton variant="secondary" onClick={handleClick}>
      {t("product.details.archive.form.button.label")}
      <Icon name="CaretRight" />
    </XxlButton>
  );
};
