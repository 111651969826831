export const QUERY_KEYS = {
  CAMPAIGN_DATA: "campaign-data",
  CLICK_AND_COLLECT_STORES_SORTED: "c&c-stores-sorted",
  ECO_ONLINE: "eco-online",
  ELEVATE_BUNDLE: "elevate-bundle",
  ELEVATE_PRODUCT_PAGE: "elevate-product-page",
  EXPERT_REVIEW: "expert-review",
  PAYMENT_WIDGET: "payment-widget",
  PDP_ACCESSORIES: "pdp-acessories",
  PRODUCT_LIST: "product-list",
  PRODUCT_TOOLTIPS: "product-tooltips",
  REVIEWS: "reviews",
  RECOMMENDATION: "recommendation",
  SIZE_GUIDES: "size-guides",
  SIZE_RECOMMENDATION: "size-recommendation",
  SIZE_STOCK_STATUS: "size-stock-status",
  STORES: "stores",
  STORE_DETAILS: "store",
} as const;

export const MUTATION_KEYS = {
  REPORT_REVIEW: "report-review",
} as const;
