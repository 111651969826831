import { NextImage } from "@/components/common/NextImage/NextImage";
import { ProductRecommendations } from "@/components/PersonalizedProductLists/Handler/Handler";
import { RecommendationStrategies } from "@/components/PersonalizedProductLists/Handler/Handler.helper";
import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import Breakpoints from "@/react-app/styles/breakpoints.config";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { getImagePriority } from "@/react-components/Banners/Shared/BannerContent/BannerContent.helper";
import { BannerVideo } from "@/react-components/CategoryContent/CategoryComponents/BannerVideo";
import { CaretIcon } from "@/react-components/Common/Icons/Caret";
import { DisclaimerInformation } from "@/react-components/DisclaimerInformation";
import { CategoryIcon } from "@/react-components/MegaMenu/Mobile/CategoryIcon";
import { stringToRecommendationStrategy } from "@/react-components/PersonalizedProductList/personalized-product-list-helper";
import {
  LinkDisplayName,
  PersonalizedWrapper,
  Link as StyledLink,
} from "@/react-components/PersonalizedProductList/ProductList.styled";
import {
  BREAKPOINTS,
  useBreakpoint,
} from "@/react-hooks/useBreakpoint/useBreakpoint";
import { useXxlMediaQuery } from "@/react-hooks/useXxlMediaQuery";
import {
  hasNoValue,
  hasValue,
  isNotEmpty,
  isNotNull,
  isNotNullOrUndefined,
} from "@xxl/common-utils";
import {
  type ContentProductCarousel as ContentProductCarouselDataProps,
  type Image,
  type InternalIdentifier,
  type Link,
  type Video,
} from "@xxl/content-api";
import { ArrowRight } from "@xxl/icons";
import { useEffect, useState } from "react";
import {
  CarouselWrapper,
  Description,
  DescriptionLinkIndicator,
  DescriptionLinkWrapper,
  DisclaimerInformationWrapper,
  Heading,
  HeadingTextWrapper,
  ImageWrapper,
  MediaWrapper,
  SliderWrapper,
  Wrapper,
} from "./ContentProductCarousel.styled";

const { spaces } = xxlTheme;

type ContentProductCarouselProps = {
  contentData: ContentProductCarouselDataProps & InternalIdentifier;
  isHighPrioComponent: boolean;
};

const DEFAULT_IMAGE_POSITION = "left";
const IMAGE_POSITION_RIGHT = "right";

export const ContentProductCarouselComponent: React.FunctionComponent<
  ContentProductCarouselProps
> = ({ contentData, isHighPrioComponent }) => {
  const {
    productRecommendation,
    visualMedia,
    descriptionField,
    titleField,
    buttons,
    disclaimerInformation,
  } = contentData;
  const breakpoint = useBreakpoint();
  const { t } = useTranslations();

  const hasImage =
    "image" in visualMedia && isNotNullOrUndefined(visualMedia.image);
  const imagePosition = contentData.imagePosition ?? DEFAULT_IMAGE_POSITION;
  const imageData = hasImage ? (visualMedia.image as Image) : undefined;
  const hasVideo =
    "video" in visualMedia && isNotNullOrUndefined(visualMedia.video);
  const videoData = hasVideo ? (visualMedia.video as Video) : undefined;
  const hasDescription = isNotNullOrUndefined(descriptionField?.description);
  const [mediaWidth, setMediaWidth] = useState("0");
  const [sliderWidth, setSliderWidth] = useState("100%");
  const [iconSize, setIconSize] = useState(36);
  const imageSizes = `(max-width: ${Breakpoints.tablet}px) 100vw, (max-width: ${
    Breakpoints.laptop - 1
  }px) 33vw, (max-width: ${Breakpoints.desktop - 1}px) 25vw, 33vw`;

  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");
  const disclaimerInformationIconSize = isLaptop ? 24 : 18;

  useEffect(() => {
    if (isNotNullOrUndefined(videoData)) {
      if (
        breakpoint === BREAKPOINTS.mobile ||
        breakpoint === BREAKPOINTS.tablet
      ) {
        setMediaWidth(`calc(100dvw - 2 * ${spaces.smallRegular})`);
        setSliderWidth("100%");
      } else if (breakpoint === BREAKPOINTS.laptop) {
        setMediaWidth("546px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.huge} - 546px)`);
      } else if (breakpoint === BREAKPOINTS.desktop) {
        setMediaWidth("666px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.huge} - 666px)`);
      } else {
        setSliderWidth("666px");
      }
    }
    if (isNotNullOrUndefined(imageData)) {
      if (breakpoint === BREAKPOINTS.mobile) {
        setMediaWidth(`calc(100dvw - 2 * ${spaces.smallRegular})`);
        setSliderWidth("100%");
      } else if (breakpoint === BREAKPOINTS.tablet) {
        setMediaWidth("280px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.smallRegular} - 280px)`);
      } else if (breakpoint === BREAKPOINTS.laptop) {
        setMediaWidth("320px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.huge} - 320px)`);
      } else if (breakpoint === BREAKPOINTS.desktop) {
        setMediaWidth("440px");
        setSliderWidth(`calc(100dvw - 2 * ${spaces.huge} - 440px)`);
      } else {
        setMediaWidth("440px");
        setSliderWidth("892px");
      }
    }
    if (isNotNullOrUndefined(titleField.categoryTitle)) {
      setIconSize(breakpoint === BREAKPOINTS.mobile ? 36 : 48);
    }
  }, [breakpoint, imageData, titleField.categoryTitle, videoData]);

  const categoriesArray = isNotNullOrUndefined(titleField.categoryTitle)
    ? titleField.categoryTitle
    : null;

  const category = isNotNullOrUndefined(categoriesArray)
    ? categoriesArray[0]
    : null;

  const titleText = isNotNullOrUndefined(titleField.title)
    ? titleField.title
    : isNotNull(category)
      ? category.displayName
      : "";

  const descriptionButtons = isNotNullOrUndefined(descriptionField?.buttons)
    ? (descriptionField.buttons as Link[])
    : null;

  const descriptionButton = isNotNull(descriptionButtons)
    ? descriptionButtons[0]
    : null;

  const titleButtons = isNotNullOrUndefined(buttons) ? buttons : null;
  const titleButton = isNotNullOrUndefined(titleButtons)
    ? titleButtons[0]
    : null;

  if (hasNoValue(productRecommendation)) {
    return null;
  }

  const {
    brandNames,
    campaignIds,
    categoryCodes,
    productIds,
    productsCount,
    strategy,
  } = productRecommendation;

  if (
    strategy !== RecommendationStrategies.bestseller &&
    strategy !== RecommendationStrategies.personalized &&
    strategy !== RecommendationStrategies.popularity
  ) {
    return null;
  }

  const Media = () => (
    <MediaWrapper
      componentWidth={mediaWidth}
      paddingLeft={imagePosition !== DEFAULT_IMAGE_POSITION}
      paddingRight={imagePosition === DEFAULT_IMAGE_POSITION}
      hasVideo={hasVideo}
      data-testid="content-product-carousel-media"
    >
      {hasImage && isNotNullOrUndefined(imageData) && (
        <>
          {hasValue(disclaimerInformation) && (
            <DisclaimerInformationWrapper>
              <DisclaimerInformation
                buttonText={t("general.close")}
                title={t("general.information")}
                iconSize={disclaimerInformationIconSize}
                color={disclaimerInformation.buttonColor}
              >
                {disclaimerInformation.description}
              </DisclaimerInformation>
            </DisclaimerInformationWrapper>
          )}
          <DescriptionLinkWrapper
            href={descriptionButton?.url ?? titleButton?.url}
          >
            {isNotEmpty(imageData.url) && (
              <ImageWrapper hasDescription={hasDescription}>
                <NextImage
                  src={imageData.url}
                  width={416}
                  height={235}
                  sizes={imageSizes}
                  alt={imageData.alt ?? ""}
                  {...getImagePriority(isHighPrioComponent)}
                />
              </ImageWrapper>
            )}

            {hasDescription && (
              <Description>{descriptionField.description}</Description>
            )}
            {isNotNull(descriptionButton) && (
              <DescriptionLinkIndicator>
                {descriptionButton.displayName}
                <ArrowRight />
              </DescriptionLinkIndicator>
            )}
          </DescriptionLinkWrapper>
        </>
      )}
      {hasVideo && isNotNullOrUndefined(videoData) && (
        <BannerVideo
          origin={videoData.origin}
          url={videoData.url}
          isContentProductCarousel={true}
        />
      )}
    </MediaWrapper>
  );

  return (
    <PersonalizedWrapper
      carouselType={stringToRecommendationStrategy(
        hasValue(productRecommendation.strategy)
          ? productRecommendation.strategy.toString()
          : RecommendationStrategies.personalized
      )}
      colorTheme={productRecommendation.colorTheme}
      isNextApp={true}
      data-testid="content-product-carousel"
    >
      <Wrapper>
        <Heading>
          {isNotNull(category) && (
            <CategoryIcon
              code={String(category.ecomCode)}
              size={iconSize}
              showFallback={true}
            />
          )}
          <HeadingTextWrapper>{titleText}</HeadingTextWrapper>
          {isNotNull(titleButton) && (
            <StyledLink
              href={titleButton.url}
              carouselType={stringToRecommendationStrategy(
                hasValue(productRecommendation.strategy)
                  ? productRecommendation.strategy.toString()
                  : RecommendationStrategies.personalized
              )}
              colorTheme={productRecommendation.colorTheme}
            >
              <LinkDisplayName>{titleButton.displayName}</LinkDisplayName>
              <CaretIcon direction="right" />
            </StyledLink>
          )}
        </Heading>
        <CarouselWrapper hasVideo={hasVideo}>
          {(isNotNullOrUndefined(imageData) ||
            isNotNullOrUndefined(videoData)) &&
            imagePosition === DEFAULT_IMAGE_POSITION && <Media />}
          <SliderWrapper
            componentWidth={sliderWidth}
            hasVideo={hasVideo}
            imagePosition={String(imagePosition)}
          >
            <ProductRecommendations
              brands={brandNames}
              campaigns={campaignIds}
              categories={categoryCodes}
              includedProducts={productIds}
              strategy={strategy}
              type={"SUB_COMPONENT"}
              sliderConfig={{
                slidesPerView: {
                  laptop: 4,
                  mobile: 2,
                  tablet: 3,
                },
                spacing: 6,
              }}
              limit={productsCount}
            />
          </SliderWrapper>
          {(isNotNullOrUndefined(imageData) ||
            isNotNullOrUndefined(videoData)) &&
            imagePosition === IMAGE_POSITION_RIGHT && <Media />}
        </CarouselWrapper>
      </Wrapper>
    </PersonalizedWrapper>
  );
};
