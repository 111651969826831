/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useMediaQuery } from "@mui/material";
import { isNotEmpty } from "@xxl/common-utils";
import React, { useEffect, useState } from "react";
import { useStateValue } from "cotton-box-react";
import { MISSING_ACCOUNT_DATA } from "../../constants";
import {
  useModalsStatusSource,
  useSessionSource,
} from "../../contexts/Session";
import { useSharedData } from "../../contexts/SharedData";
import { useClient } from "../../hooks/useClient/useClient";
import { useCookieConsent } from "../../hooks/useCookieConsent/useCookieConsent";
import * as XxlEvent from "../../utils/xxl-event";
import { ViewState } from "../../utils/xxl-event";
import { tabletMediaQuery } from "../../utils/xxl-screen";
import { Cart } from "../Cart/Cart";
import { ToggleFeature } from "../Common/FeatureToggle";
import { FavoritesConfirmation } from "../Favorites/FavoritesConfirmation";
import { MiniFavorites } from "../Favorites/MiniFavorites";
import { closeDropdownFilter } from "../Filter/FilterHelper";
import { useFilterContext } from "../Filter/FilterState";
import { MegaMenu as DesktopMegaMenu } from "../MegaMenu/Desktop";
import { LoginModalWrapper } from "../Reward/Login/LoginModalWrapper";
import { SignupViewStates } from "../Reward/SignUp/SignUpModal";
import { SignupModalWrapper } from "../Reward/SignUp/SignupModalWrapper";
import { SearchBox } from "../SearchBox/SearchBox";
import {
  StickyHeaderStatus,
  handleRegisterLoginRedirectParams,
} from "./Header.helper";
import { FlexWrapper, GridWrapper } from "./Header.styled";
import { HeaderLinks } from "./HeaderLinks";
import { MobileMenuButton } from "./MobileMenuButton";
import { MyAccountLink } from "./MyAccountLink";
import { Wrapper } from "./Wrapper";
import { XxlLogoLink } from "./XxlLogoLink";
import {
  DESKTOP_HEIGHT,
  DESKTOP_HEIGHT_WITH_HEADER_LINKS,
  HIDDEN_CLASS,
  MOBILE_HEIGHT,
  MOBILE_HEIGHT_WITH_HEADER_LINKS,
} from "./constants";
import Snow from "../Snowfall/Snowfall";
import type { HeaderProps } from "./types";

const Header = ({
  currentCategoryCode,
  headerCode,
  headerConfig,
  initialHeaderLinks,
  megaMenuContent,
}: HeaderProps) => {
  useCookieConsent();
  const isTabletMediaQuery = useMediaQuery(tabletMediaQuery); // Will be false on first render in NextJS
  const isMobile = !isTabletMediaQuery; // Invert boolean to render mobile first view
  const { featureToggles } = useSharedData().data;
  const {
    campaignHubUrl,
    headerLinks: headerLinksFromConfig,
    logoAlt,
    logoUrl,
    showHeaderLinks,
    isTeamsales,
  } = headerConfig;
  const {
    toggle_vipps_signup,
    toggle_disable_checkout_coupons: disableCheckoutCoupons,
  } = featureToggles;
  const isLoggedIn = useStateValue(useSessionSource);
  const headerLinks = initialHeaderLinks ?? headerLinksFromConfig;
  const [scrollPos, setScrollPos] = useState(0);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [stickyHeaderElement, setStickyHeaderElement] =
    useState<HTMLElement | null>(null);
  const [reactHeaderElement, setReactHeaderElement] =
    useState<HTMLElement | null>(null);
  const [searchElement, setSearchElement] = useState<HTMLElement | null>(null);
  const className = isHidden ? HIDDEN_CLASS : "";
  const scrollEvent = isMobile ? "scroll" : "wheel";
  const initialSignupViewState = toggle_vipps_signup
    ? SignupViewStates.SIGN_UP_PHONE
    : SignupViewStates.SIGN_UP;
  const {
    dispatch,
    state: { expandedTopFilter },
  } = useFilterContext();
  const isClient = useClient();
  const onboardingModalSource = useStateValue(useModalsStatusSource);
  const { isCompleteDataFormVisible } = onboardingModalSource;

  let hideStartPos = DESKTOP_HEIGHT;
  if (isMobile) {
    if (isLoggedIn || showHeaderLinks) {
      hideStartPos = MOBILE_HEIGHT_WITH_HEADER_LINKS;
    } else {
      hideStartPos = MOBILE_HEIGHT;
    }
  } else if (isLoggedIn || showHeaderLinks) {
    hideStartPos = DESKTOP_HEIGHT_WITH_HEADER_LINKS;
  }

  const handleHideHeader = () => {
    const isFixed =
      stickyHeaderElement !== null &&
      stickyHeaderElement.classList.contains("header--fixed")
        ? true
        : false;

    const isNonSticky =
      stickyHeaderElement !== null &&
      stickyHeaderElement.classList.contains("header--non-sticky")
        ? true
        : false;

    if (
      !isFixed &&
      !isNonSticky &&
      scrollPos < window.scrollY &&
      window.scrollY > hideStartPos
    ) {
      setIsHidden(true);
      reactHeaderElement?.classList.toggle(HIDDEN_CLASS, true);
      searchElement?.blur();
    }
  };

  useEffect(() => {
    setStickyHeaderElement(document.getElementById("js-sticky-header"));
    setReactHeaderElement(document.getElementById("js-react-header"));
    setSearchElement(document.getElementById("query"));
  }, []);

  useEffect(() => {
    if (
      isLoggedIn &&
      localStorage.getItem(MISSING_ACCOUNT_DATA) &&
      !isCompleteDataFormVisible
    ) {
      XxlEvent.dispatchOpenLoginEvent(ViewState.MISSING_USER_DATA);
    }

    handleRegisterLoginRedirectParams(isLoggedIn);
  }, [isLoggedIn, isCompleteDataFormVisible]);

  let scrollTimeout: ReturnType<typeof setTimeout>;
  const scrollDelay = 5;

  const handleScroll = () => {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(function () {
      setScrollPos(window.scrollY);

      if (scrollPos > window.scrollY) {
        setIsHidden(false);
        StickyHeaderStatus.set(false);
        reactHeaderElement?.classList.toggle(HIDDEN_CLASS, false);
        if (!isMobile && isNotEmpty(expandedTopFilter)) {
          closeDropdownFilter(dispatch);
        }
      } else if (scrollPos < window.scrollY) {
        StickyHeaderStatus.set(true);
      }

      handleHideHeader();
    }, scrollDelay);
  };

  useEffect(() => {
    if (isTeamsales) {
      return;
    }

    setScrollPos(window.scrollY);
    window.addEventListener(scrollEvent, handleScroll, { passive: true });

    return () => {
      window.removeEventListener(scrollEvent, handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideStartPos, isTeamsales, scrollPos]);

  const favoritesLoggedInUser = isLoggedIn && isClient;
  if (isLoggedIn || showHeaderLinks) {
    return (
      <>
        <Wrapper
          campaignHubUrl={campaignHubUrl}
          megaMenuContent={megaMenuContent}
          headerCode={headerCode}
          headerLinks={headerLinks}
          className={className}
          isTeamsales={isTeamsales}
        >
          <ToggleFeature flag="toggle_christmas_mode">
            <Snow />
          </ToggleFeature>
          <>
            <GridWrapper
              className="container"
              isLoggedIn={favoritesLoggedInUser}
            >
              {isMobile && <MobileMenuButton />}
              <XxlLogoLink logoAlt={logoAlt} logoUrl={logoUrl} />
              {!isMobile && <SearchBox hideSuggestions={isHidden} />}
              <MyAccountLink />
              {isLoggedIn && isClient && <MiniFavorites />}
              <Cart
                isMiniCart={true}
                disableCheckoutCoupons={disableCheckoutCoupons}
              />
              {isMobile && <SearchBox hideSuggestions={isHidden} />}
            </GridWrapper>
            {isTabletMediaQuery && (
              <GridWrapper
                className="container container__category-links"
                isLoggedIn={favoritesLoggedInUser}
              >
                <DesktopMegaMenu
                  categoryLinks={megaMenuContent.links ?? []}
                  currentCategoryCode={currentCategoryCode}
                  campaignHubUrl={campaignHubUrl}
                  headerLinks={headerLinks}
                  isTeamsales={isTeamsales}
                />
              </GridWrapper>
            )}
          </>
          <SignupModalWrapper initialViewState={initialSignupViewState} />
          {isClient && <FavoritesConfirmation />}
        </Wrapper>
        <LoginModalWrapper />
      </>
    );
  }

  return (
    <>
      <Wrapper
        campaignHubUrl={campaignHubUrl}
        headerCode={headerCode}
        headerLinks={headerLinks}
        megaMenuContent={megaMenuContent}
        className={className}
        isTeamsales={isTeamsales}
      >
        <ToggleFeature flag="toggle_christmas_mode">
          <Snow />
        </ToggleFeature>
        <FlexWrapper className="container">
          {isMobile && <MobileMenuButton />}
          <XxlLogoLink logoAlt={logoAlt} logoUrl={logoUrl} />
          {isMobile === false && <HeaderLinks links={headerLinks} />}
          <MyAccountLink />
        </FlexWrapper>

        <SignupModalWrapper initialViewState={initialSignupViewState} />
      </Wrapper>
      <LoginModalWrapper />
    </>
  );
};

export { Header };
