export const addToFavoritesMutation = /* GraphQL */ `
  mutation AddToFavorites($styleId: String!) {
    addToFavorites(styleId: $styleId) {
      styleId
    }
  }
`;

export const removeFromFavoritesMutation = /* GraphQL */ `
  mutation RemoveFromFavorites($styleId: String!) {
    removeFromFavorites(styleId: $styleId) {
      styleId
    }
  }
`;
