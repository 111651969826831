import { RecommendationStrategies } from "@/components/PersonalizedProductLists/Handler/Handler.helper";
import { ArrowRight, Xxl } from "@xxl/icons";
import { useTranslations } from "react-app/src/contexts/Translations/TranslationsContext";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import { CTAContainer, Link } from "./LinkCarousel.styled";

export const LinkInCarousel = () => {
  const { t } = useTranslations();
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const iconSize = isLaptopSize ? 80 : 40;

  return (
    <Link href={`#${RecommendationStrategies.upsale}`}>
      <CTAContainer>
        <span>{t("general.see.similar.products")}</span>
        <ArrowRight fontSize={iconSize} />
      </CTAContainer>
      <Xxl fontSize={iconSize} />
    </Link>
  );
};
