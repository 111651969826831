import { useSymplify } from "@/react-utils/Symplify/hooks";
import { PaymentProviderMessage } from "../PaymentProviderMessage/PaymentProviderMessage";
import { PAYMENT_VARIANT, WalleyStaticWidget } from "./WalleyStaticWidget";
import type { PaymentProviderMessageConfig } from "../types";
import { AB_TEST_DEFAULT_NAME } from "@/react-utils/Symplify/constants";

type Props = {
  isGraveyard: boolean;
  shouldShowWalleyWidget: boolean;
  productSalesPrice: number;
  paymentProviderMessageConfig: PaymentProviderMessageConfig;
  walleyWidgetRef: React.RefObject<HTMLDivElement>;
};
export const PaymentProviderContainer = ({
  isGraveyard,
  shouldShowWalleyWidget,
  productSalesPrice,
  paymentProviderMessageConfig,
  walleyWidgetRef,
}: Props) => {
  const { isTestVariant: isAbTestWallyVariationOne } = useSymplify(
    "pdp-walley-widget",
    AB_TEST_DEFAULT_NAME.ONE
  );
  const { isTestVariant: isAbTestWallyVariationTwo } = useSymplify(
    "pdp-walley-widget",
    AB_TEST_DEFAULT_NAME.TWO
  );
  if (isGraveyard || !paymentProviderMessageConfig.isActive) {
    return null;
  }
  if (
    (shouldShowWalleyWidget && isAbTestWallyVariationOne) ||
    isAbTestWallyVariationTwo
  ) {
    return (
      <WalleyStaticWidget
        variant={
          isAbTestWallyVariationOne
            ? PAYMENT_VARIANT.WALLEY
            : PAYMENT_VARIANT.XXL_PAY
        }
      />
    );
  }
  if (shouldShowWalleyWidget && !isAbTestWallyVariationOne) {
    return <div ref={walleyWidgetRef} />;
  }
  return (
    <PaymentProviderMessage
      locale={paymentProviderMessageConfig.locale}
      productSalesPrice={productSalesPrice}
    />
  );
};
