import type { ProductTooltipContentData } from "@xxl/content-api";
import {
  ContentApi,
  Configuration as ContentApiConfig,
} from "@xxl/content-api";
import type { V3ConfigurationInfoData } from "@xxl/pim-api";
import { PimApi, Configuration as PimApiConfig } from "@xxl/pim-api";
import type { ParameterProductInfo } from "@xxl/product-configurator-api-library";
import noop from "lodash/noop";
import * as React from "react";
import {
  addEventListener,
  dispatchEvent,
  type,
} from "../../../../src/utils/xxl-event";
import { useSharedData } from "../../../contexts/SharedData";
import type { BusyState } from "../../../hooks/useAddToCart/useAddToCart";
import { ConfigurationModal } from "./ConfigurationModal";
import type { Configuration } from "./ConfigurationModal/ConfigurationForm/types";

type ConfigurationModalWrapperProps = {
  productCode: string;
  onClose?: () => void;
  onSubmit?: (configuration: Configuration) => Promise<void>;
  open?: boolean;
  setAddToCartState?: (state: BusyState) => void;
};

export type CombinedConfigurationType =
  | V3ConfigurationInfoData
  | ParameterProductInfo;

export type ProductConfiguration = CombinedConfigurationType & {
  tooltip?: ProductTooltipContentData;
};

export const ConfigurationModalWrapper: React.FunctionComponent<
  ConfigurationModalWrapperProps
> = ({
  onSubmit,
  onClose = noop,
  open = false,
  productCode,
  setAddToCartState,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [configuration, setConfiguration] = React.useState<
    ProductConfiguration[] | null
  >(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const handleOpenEvent = (): void => setIsOpen(true);
  const handleOnClose = (): void => {
    setIsOpen(false);
    onClose();
  };
  const defaultOnSubmit = (conf: Configuration) => {
    dispatchEvent(type.XXL_CONFIG_PRODUCT_SUBMIT, conf);
    setIsOpen(false);
    return Promise.resolve();
  };
  const handleSubmit = onSubmit ?? defaultOnSubmit;
  const { configuration: apiConfiguration, siteUid } = useSharedData().data;
  const [contentApi] = React.useState(
    new ContentApi(new ContentApiConfig(apiConfiguration.contentApi))
  );
  const [pimApi] = React.useState(
    new PimApi(new PimApiConfig(apiConfiguration.pimApi))
  );

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);

  React.useEffect(() => {
    if (setAddToCartState === undefined) {
      return;
    }

    setAddToCartState(isLoading ? "LOADING_REGULAR_ADD_TO_CART" : "READY");
  }, [isLoading, setAddToCartState]);

  const getConfiguration = React.useCallback((): void => {
    setIsLoading(true);
    try {
      setConfiguration(null);
    } finally {
      setIsLoading(false);
    }
  }, [contentApi, productCode, pimApi, siteUid]);

  React.useEffect(() => {
    addEventListener(type.XXL_CONFIG_PRODUCT_OPEN, handleOpenEvent);
    void getConfiguration();

    return (): void => {
      document.body.removeEventListener(
        type.XXL_CONFIG_PRODUCT_OPEN,
        handleOpenEvent
      );
    };
  }, [getConfiguration]);

  return (
    <ConfigurationModal
      configuration={configuration}
      isOpen={isOpen}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      onClose={handleOnClose}
      onReload={() => {
        void getConfiguration();
      }}
    />
  );
};
