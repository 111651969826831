import type { DisclaimerInformation } from "@/react-components/DisclaimerInformation";
import { hasNoValue, hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import { color } from "@xxl/theme";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import { CampaignCarousel } from "./CampaignCarousel";
import type { CampaignCarouselProps } from "./CampaignCarousel.helper";

type CampaignCarouselComponentProps = {
  module: {
    backgroundColor: {
      title: string;
      value: string;
    };
    descriptionField: {
      description?: string;
      mobileDescription?: string;
      color: string;
    };
    image: {
      alt?: string;
      hotspot?: {
        x?: number;
        y?: number;
      };
      url?: string;
    };
    titleField: {
      color: string;
      title?: string;
    };
    buttons?: {
      displayName: string;
      url: string;
      buttonColor?: string;
    }[];
    disclaimerInformation?: DisclaimerInformation;
    mobileImage?: {
      alt?: string;
      hotspot?: {
        x?: number;
        y?: number;
      };
      url?: string;
    };
    redirectLink?: {
      url: string;
    };
  };
  recommendationInfo: CampaignCarouselProps["recommendationInfo"];
  isHighPrioComponent: boolean;
};

const getCampaignCarouselDescription = (
  descriptionField:
    | {
        description?: string;
        mobileDescription?: string;
        color: string;
      }
    | undefined,
  isLaptopSize: boolean
) => {
  if (hasNoValue(descriptionField)) {
    return undefined;
  }
  const { description, mobileDescription, color } = descriptionField;
  return hasValue(description)
    ? { text: isLaptopSize ? description : mobileDescription, color }
    : undefined;
};

const CampaignCarouselComponent = ({
  module: {
    backgroundColor: { value: backgroundColor },
    buttons,
    descriptionField,
    disclaimerInformation,
    image,
    mobileImage,
    redirectLink,
    titleField,
  },
  recommendationInfo,
  isHighPrioComponent,
}: CampaignCarouselComponentProps) => {
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");

  const title = {
    text: titleField.title,
    color: titleField.color,
  };
  const imageData = isNotNullOrUndefined(image.url)
    ? {
        desktopSrc: image.url,
        alt: image.alt ?? "",
        mobileSrc: mobileImage?.url,
        hotspot:
          isNotNullOrUndefined(image.hotspot) &&
          isNotNullOrUndefined(image.hotspot.x) &&
          isNotNullOrUndefined(image.hotspot.y)
            ? {
                x: image.hotspot.x,
                y: image.hotspot.y,
              }
            : undefined,
      }
    : undefined;

  if (isNotNullOrUndefined(buttons) && buttons.length > 0) {
    const [button] = buttons;
    return (
      <CampaignCarousel
        backgroundColor={backgroundColor}
        headerType="BUTTON_AS_LINK"
        image={imageData}
        link={
          isNotNullOrUndefined(redirectLink)
            ? { href: redirectLink.url }
            : undefined
        }
        button={{
          href: button.url,
          text: button.displayName,
          backgroundColor:
            button.buttonColor?.toLowerCase() ===
            color.webBlack.hex.toLocaleLowerCase()
              ? color.webBlack.hex
              : color.white.hex,
        }}
        title={title}
        disclaimerInformation={disclaimerInformation}
        description={getCampaignCarouselDescription(
          descriptionField,
          isLaptopSize
        )}
        recommendationInfo={recommendationInfo}
        isHighPrioComponent={isHighPrioComponent}
      />
    );
  }

  if (isNotNullOrUndefined(redirectLink)) {
    return (
      <CampaignCarousel
        backgroundColor={backgroundColor}
        headerType="HEADER_AS_LINK"
        image={imageData}
        link={{
          href: redirectLink.url,
        }}
        title={title}
        disclaimerInformation={disclaimerInformation}
        description={getCampaignCarouselDescription(
          descriptionField,
          isLaptopSize
        )}
        recommendationInfo={recommendationInfo}
        isHighPrioComponent={isHighPrioComponent}
      />
    );
  }

  log.error("Campaign carousel has invalid product recommendation config.");
  return null;
};

export { CampaignCarouselComponent };
