import { useMediaQuery } from "@mui/material";
import sum from "lodash/sumBy";
import { useEffect, useState } from "react";
import { useTracking } from "../../contexts/Tracking";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import type { TranslationKey } from "../../translations";
import {
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
} from "../../utils/xxl-screen";
import {
  AutoSuggestionsWrapper,
  MobileProductsList,
  ShowAllLink,
  SuggestionsColumn,
  Tab,
  TabsList,
} from "./AutoSuggestion.styled";
import { AutoSuggestionSection } from "./AutoSuggestionSection";
import { ProductsSectionWrapper } from "./AutoSuggestionSection.styled";
import { Product } from "./Product";
import type { ProductsSectionProps } from "./ProductsSection";
import { ProductsSection } from "./ProductsSection";
import {
  SearchSuggestionsTypeEnum,
  type SearchSuggestsGroup,
} from "./searchBoxHelper";

const ITEMS_AMOUNT_TO_REDUCE = 15;
const ITEMS_LENGTH_TO_BREAK = 3;
const ITEMS_LENGTH_TO_REDUCE_MORE = 5;

const TABS = {
  OTHERS: "others",
  PRODUCTS: "products",
} as const;

type TTabs = (typeof TABS)[keyof typeof TABS];

type SuggestionsProps = {
  isTwoColumns?: boolean;
  items?: SearchSuggestsGroup[];
  products?: {
    highlightedPhrase?: string;
    items: ProductsSectionProps["products"];
  };
  query?: string;
};

export const AutoSuggestions = ({
  isTwoColumns = false,
  items,
  products,
  query,
}: SuggestionsProps) => {
  const tracking = useTracking();
  const { t } = useTranslations();
  const isMobile = useMediaQuery(mobileMediaQuery);
  const isMobileOrTablet = useMediaQuery(mobileAndTabletMediaQuery);
  const itemsAmount = sum(items, (section) => section.queries.length);
  const [activeTab, setActiveTab] = useState<TTabs>(TABS.PRODUCTS);

  const handleTabClick = (tab: TTabs) => {
    setActiveTab(tab);
  };

  const trackClick = (ticket?: string) => {
    if (ticket !== undefined) {
      tracking.sendClickEvent({ ticket });
    }
  };

  const getRenderedItems = () => {
    if (items === undefined) return [];

    if (
      items.length > ITEMS_LENGTH_TO_BREAK &&
      itemsAmount > ITEMS_AMOUNT_TO_REDUCE
    ) {
      const sliceEnd = items.length > ITEMS_LENGTH_TO_REDUCE_MORE ? 2 : 3;
      const mobileItems = items.map((item) => {
        const mobileItem = {
          ...item,
          queries: item.queries.slice(0, sliceEnd),
        };
        return mobileItem;
      });

      return isMobileOrTablet ? mobileItems : items;
    } else {
      return items;
    }
  };

  const renderedItems = getRenderedItems();

  useEffect(() => {
    setActiveTab(TABS.PRODUCTS);
  }, [query]);

  return (
    <AutoSuggestionsWrapper data-testid="search-suggestions">
      {isMobile ? (
        <>
          {items !== undefined &&
            items.length > 0 &&
            products !== undefined &&
            products.items.length > 0 && (
              <TabsList>
                <Tab
                  isActive={activeTab === TABS.OTHERS}
                  onClick={() => handleTabClick(TABS.OTHERS)}
                >
                  {t("header.search.suggestions.results")}
                </Tab>
                <Tab
                  isActive={activeTab === TABS.PRODUCTS}
                  onClick={() => handleTabClick(TABS.PRODUCTS)}
                >
                  {t("header.search.suggestions.products")}
                </Tab>
              </TabsList>
            )}
          {activeTab === TABS.PRODUCTS && products !== undefined && (
            <ProductsSectionWrapper>
              <MobileProductsList>
                {products.items.map((item, index) => (
                  <Product
                    key={`${item.name as string}_${index}`}
                    foundPhrase={products.highlightedPhrase}
                    product={item}
                    onClick={trackClick}
                  />
                ))}
              </MobileProductsList>
            </ProductsSectionWrapper>
          )}

          {activeTab === TABS.OTHERS && renderedItems.length > 0 && (
            <SuggestionsColumn>
              {renderedItems.map((item) => {
                const type = SearchSuggestionsTypeEnum[item.type];
                return (
                  <AutoSuggestionSection
                    key={item.type}
                    headerName={t(
                      `header.search.suggestions.${type}` as TranslationKey
                    )}
                    foundPhrase={item.highlightedPhrase}
                    items={item.queries}
                    onClick={trackClick}
                  />
                );
              })}
            </SuggestionsColumn>
          )}
        </>
      ) : (
        <>
          {renderedItems.length > 0 && (
            <SuggestionsColumn twoColumns={!isMobileOrTablet && isTwoColumns}>
              {renderedItems.map((item) => {
                const type = SearchSuggestionsTypeEnum[item.type];
                return (
                  <AutoSuggestionSection
                    key={item.type}
                    headerName={t(
                      `header.search.suggestions.${type}` as TranslationKey
                    )}
                    foundPhrase={item.highlightedPhrase}
                    items={item.queries}
                    onClick={trackClick}
                  />
                );
              })}
            </SuggestionsColumn>
          )}
          {products !== undefined && products.items.length > 0 && (
            <ProductsSection
              headerName={t("header.search.suggestions.products")}
              foundPhrase={products.highlightedPhrase}
              products={products.items}
              onClick={trackClick}
            />
          )}
        </>
      )}
      {query !== undefined && (
        <ShowAllLink
          href={`/search?query=${query}`}
          data-testid="search-auto-suggestions-show-all-link"
        >
          {t("header.search.suggestions.all")}
        </ShowAllLink>
      )}
    </AutoSuggestionsWrapper>
  );
};
