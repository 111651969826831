import type { SiteIdentifier } from "@xxl/common-utils";
import type { ProductData } from "@xxl/recommendations-api";
import type { SetFieldType } from "type-fest";
import { DefaultPostalCodes } from "../../constants";
import type {
  PriceDisplayVariant,
  ProductType,
  StockStatus,
} from "../../utils/data-types";
import type { ProductCardDataV2 } from "../../utils/ProductData/product-card-data-helper";

const MAX_PRODUCTS_IN_CROSS_SALES_MODAL = 4;

export type ProductAddedToCartData = {
  brand: string;
  imageUrl: string;
  name: string;
  salesPrice: number;
  salesPriceFormatted: string;
  size: string;
};

export type CrossSalesProps = {
  category: string;
  onClickClose: () => void;
  price: string;
  productCode: string;
  productData: ProductAddedToCartData;
};

export type FreeDelivery =
  | {
      isEligible: false;
      cartTotal?: number;
      threshold?: number;
    }
  | {
      isEligible: true;
      cartTotal: number;
      threshold: number;
    };

export const siteIdToPostalCode = (id: SiteIdentifier) => {
  switch (id) {
    case "xxl-at":
      return DefaultPostalCodes.austria;
    case "xxl-dk":
      return DefaultPostalCodes.denmark;
    case "xxl-fi":
      return DefaultPostalCodes.finland;
    case "xxl-no":
      return DefaultPostalCodes.norway;
    case "xxl-se":
      return DefaultPostalCodes.sweden;
    default:
      throw Error("Invalid id.", id);
  }
};

export type Style = {
  code?: string;
  colorName?: string;
  name?: string;
  primaryImage?: string;
  stockStatus?: StockStatus;
  url?: string;
  ticket?: string;
};

export type Size = {
  code?: string;
  ean?: string;
  size?: string;
  stockStatus?: StockStatus;
  ticket?: string;
};

export type CrossSalesProductWithFixedProductType = SetFieldType<
  ProductData,
  "productType",
  ProductType | undefined
>;

export type CrossSalesProduct = {
  ticket: string;
  baseProductCode?: string;
  baseColor?: string;
  brand?: {
    name?: string;
  };
  categoryName?: string;
  code?: string;
  ean?: string[];
  name?: string;
  price: {
    salesPriceFormatted?: string;
    salesPrice?: number;
    type?: PriceDisplayVariant;
  };
  primaryImage?: string;
  productType?: ProductType;
  sizeOptions?: Size[];
  styleOptions?: Style[];
  url?: string;
};

export const productCardDataToCrossSalesProduct = ({
  baseColor,
  brandName,
  categoryBreadcrumbs,
  code,
  ean,
  primaryImage,
  price,
  name,
  url,
  styleOptions,
  ticket,
  variants,
  type,
}: ProductCardDataV2): CrossSalesProduct => {
  return {
    baseColor,
    baseProductCode: code.includes("_") ? code.split("_")[0] : code,
    brand: {
      name: brandName,
    },
    categoryName: categoryBreadcrumbs.at(-1)?.name,
    code,
    ean: [ean],
    name,
    price: {
      salesPriceFormatted: price.selling.valueFormatted,
      salesPrice: price.selling.value,
    },
    primaryImage,
    productType: type,
    styleOptions: styleOptions.map((option) => ({
      colorName: option.baseColor,
      code: option.code,
      name: option.baseColor,
      primaryImage: option.primaryImageURL,
      stockStatus: option.stockStatus,
      ticket: option.ticket,
      url: option.url,
    })),
    sizeOptions: variants.map((option) => ({
      code: option.sizeCode,
      ean: option.code,
      size: option.label,
      stockStatus: option.stockStatus,
      ticket: option.ticket,
    })),
    ticket,
    url,
  };
};

export const additionalSalesToCrossSalesProducts = (
  additionalSalesProducts: {
    accessoryProducts: ProductCardDataV2[];
    crossSalesProducts: ProductCardDataV2[];
    serviceProducts: ProductCardDataV2[];
  } | null,
  addToCartRecommendationProducts: ProductCardDataV2[] | null
): CrossSalesProduct[] => {
  const { crossSalesProducts = [] } = additionalSalesProducts ?? {};

  const returnProducts =
    crossSalesProducts.length >= MAX_PRODUCTS_IN_CROSS_SALES_MODAL
      ? crossSalesProducts.map(productCardDataToCrossSalesProduct)
      : [
          crossSalesProducts.map(productCardDataToCrossSalesProduct),
          (addToCartRecommendationProducts ?? []).map(
            productCardDataToCrossSalesProduct
          ),
        ].flat();

  return returnProducts.slice(0, MAX_PRODUCTS_IN_CROSS_SALES_MODAL);
};
