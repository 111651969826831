import { useQuery } from "@tanstack/react-query";
import { hasValue, isNotNull, isNotNullOrUndefined } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import type { BaseProductData } from "@xxl/product-search-api";
import { useStateValue } from "cotton-box-react";
import { useState } from "react";
import { SkeletonWrapper } from "react-app/src/components/Common";
import { Slider } from "react-app/src/components/Common/Slider";
import { trackProductImpression } from "react-app/src/components/PersonalizedProductList/tracking-helper";
import {
  DEFAULT_GRID_VALUE_DESKTOP,
  PERSONALIZED_PRODUCT_LIST_QUERY_NAME,
} from "react-app/src/components/Product/constants";
import {
  cardHeightMobile,
  nonHoverableCardHeight,
} from "react-app/src/components/Product/Product.styled";
import { useSessionSource } from "react-app/src/contexts/Session";
import { useSharedData } from "react-app/src/contexts/SharedData";
import { useTracking } from "react-app/src/contexts/Tracking";
import { LIMIT } from "react-app/src/hooks/useProductRecommendations/constants";
import { useProductRecommendations } from "react-app/src/hooks/useProductRecommendations/useProductRecommendations";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery";
import { toProductCardDataFromBase } from "react-app/src/utils/ProductData/product-card-data-helper";
import { withErrorBoundary } from "react-app/src/utils/WithErrorBoundary/with-error-boundary";
import { InView } from "../../common/InView/InView";
import { ProductCardV2 } from "../../ProductListV2/ProductCardV2";
// eslint-disable-next-line no-restricted-imports
import { useInView } from "react-intersection-observer";
import { QUERY_KEYS } from "../../Pdp/queries/queryKeys";
import { ProductListContainer } from "../../ProductDetailsPage/ProductDetailsPage.styles";
import { getColors, useUniqueProps, type HandlerProps } from "./Handler.helper";
import {
  HeaderWithHeadingOffset,
  InnerWrapper,
  Wrapper,
} from "./Handler.styled";

const SLIDER_MODE = "free-snap";

const _Handler = (props: HandlerProps) => {
  const {
    colorTheme,
    hasTopMargin = true,
    limit = LIMIT,
    sliderConfig = {
      slidesPerView: {
        laptop: 6,
        mobile: 2,
        tablet: 3,
      },
      spacing: 6,
    },
    sliderStyles,
    strategy,
    styleId,
    subTitle,
    title,
    type = "SELF_CONTAINED",
  } = props;
  const trackers = useTracking();
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const {
    featureToggles: { toggle_products_as_package_quantity },
    siteDefaultLanguage,
  } = useSharedData().data;
  const uniqueProps = useUniqueProps(props);
  const { brands, campaigns, categories, includedProducts, productIdsInCart } =
    uniqueProps ?? {};
  const isLoggedIn = useStateValue(useSessionSource);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const cardHeight = `${
    isLaptopSize ? nonHoverableCardHeight : cardHeightMobile
  }px`;

  const {
    getAlternativesRecs,
    getCartPageRecommendations,
    getFrequentlyBoughtTogether,
    getPersonalRecs,
    getRecentlyViewed,
    getTopProductsRecs,
  } = useProductRecommendations();

  const fetchRecommendations = async () => {
    if (uniqueProps === null) {
      return;
    }

    const handleResponse = ({
      baseProducts,
    }: {
      baseProducts: BaseProductData[];
    }) => {
      if (baseProducts.length === 0) {
        return [];
      }
      const hasRewardsPrices = baseProducts.some(
        ({ products }) => products.at(0)?.price.type === "REWARD"
      );
      return baseProducts.map((baseProduct, index) => {
        if (index === limit) {
          return null;
        }

        const { url, ...productData } = toProductCardDataFromBase(baseProduct);
        const {
          baseColor,
          brandName,
          code,
          name,
          price: { selling },
        } = productData;
        const separator = url.includes("?") ? "&" : "?";
        const productListParameter = `${PERSONALIZED_PRODUCT_LIST_QUERY_NAME}=${strategy}`;

        return (
          <InView
            key={productData.code}
            onInView={() =>
              trackProductImpression({
                listName: strategy,
                position: index + 1,
                product: {
                  brandName,
                  code,
                  name,
                  style: baseColor,
                  salesPrice: selling.value,
                },
                trackers,
              })
            }
          >
            <ProductCardV2
              productData={{
                ...productData,
                url: `${url}${separator}${productListParameter}`,
              }}
              isHoverable={false}
              isSliderProductList={true}
              isLaptopViewPort={isLaptopSize}
              nrOfSelectedColumns={DEFAULT_GRID_VALUE_DESKTOP}
              siteDefaultLanguage={siteDefaultLanguage}
              toggleProductsAsPackageQuantity={
                toggle_products_as_package_quantity
              }
              arrayIndex={index}
              hasRewardPrices={hasRewardsPrices}
            />
          </InView>
        );
      });
    };

    try {
      switch (strategy) {
        case "frequently-bought-together":
          return handleResponse({
            baseProducts: await getFrequentlyBoughtTogether(styleId),
          });
        case "upsale":
          return handleResponse({
            baseProducts: await getAlternativesRecs(styleId),
          });
        case "recently":
          return handleResponse({
            baseProducts: await getRecentlyViewed(styleId),
          });
        case "bestseller":
        case "popularity":
          return handleResponse({
            baseProducts: await getTopProductsRecs({
              ...(isNotNullOrUndefined(brands) && {
                brandNames: brands,
              }),
              ...(isNotNullOrUndefined(campaigns) && {
                campaignIds: campaigns,
              }),
              ...(isNotNullOrUndefined(categories) && {
                categoryIds: categories,
              }),
              maxNrOfProducts: limit,
              ...(isNotNullOrUndefined(includedProducts) && {
                productKeys: includedProducts,
              }),
            }),
          });
        case "personalized":
          return handleResponse({
            baseProducts: await getPersonalRecs({
              ...(isNotNullOrUndefined(brands) && { brandNames: brands }),
              ...(isNotNullOrUndefined(campaigns) && {
                campaignIds: campaigns,
              }),
              ...(isNotNullOrUndefined(categories) && {
                categoryIds: categories,
              }),
              maxNrOfProducts: limit,
              ...(isNotNullOrUndefined(includedProducts) && {
                productKeys: includedProducts,
              }),
            }),
          });
        case "cart":
          return handleResponse({
            baseProducts: await getCartPageRecommendations(
              productIdsInCart ?? []
            ),
          });
        default:
          log.error("No such strategy: ", strategy);
      }
    } catch (error) {
      log.error("Could not fetch recommendations.");
      log.debug("Could not fetch recommendations.", error);
      return [];
    }

    return [];
  };

  const { ref: inViewRef } = useInView({
    triggerOnce: true,
    threshold: 0.01,
    onChange: (inView) => {
      setShouldFetchData(inView);
    },
    rootMargin: "300px 0px",
  });
  const { data: carouselProducts, error } = useQuery({
    queryKey: [
      QUERY_KEYS.RECOMMENDATION,
      { strategy, brands, campaigns, categories, includedProducts, isLoggedIn },
    ],
    queryFn: fetchRecommendations,
    enabled: shouldFetchData,
  });
  const { background, heading } = getColors(strategy, colorTheme);

  if (carouselProducts !== undefined && carouselProducts.length === 0) {
    return null;
  }

  if (error !== null) {
    log.debug("Recommendation could not be fetched.", {
      strategy,
      brands,
      campaigns,
      categories,
      includedProducts,
      isLoggedIn,
    });
  }

  const slidesConfig = {
    perView: isLaptopSize
      ? sliderConfig.slidesPerView.laptop
      : isMobile
        ? sliderConfig.slidesPerView.mobile
        : sliderConfig.slidesPerView.tablet,
    spacing: sliderConfig.spacing,
  };

  if (type === "SUB_COMPONENT") {
    return (
      <Wrapper color={background} ref={inViewRef}>
        <InnerWrapper hasPadding={false}>
          <div style={{ minHeight: cardHeight }}>
            <SkeletonWrapper isLoading={carouselProducts === undefined}>
              <div
                style={{
                  ...{ minHeight: cardHeight, width: "100%" },
                  ...sliderStyles,
                }}
              >
                <Slider
                  items={carouselProducts?.filter(isNotNull) ?? []}
                  mode={SLIDER_MODE}
                  navigation={isLaptopSize ? "ARROWS" : "DOTS"}
                  slidesConfig={slidesConfig}
                />
              </div>
            </SkeletonWrapper>
          </div>
        </InnerWrapper>
      </Wrapper>
    );
  }

  const filteredProducts = carouselProducts?.filter(isNotNull) ?? [];

  return (
    <ProductListContainer
      data-testid={`product-recommendations-${strategy}`}
      hasTopMargin={hasTopMargin}
    >
      <Wrapper color={background} ref={inViewRef}>
        <InnerWrapper hasPadding={true}>
          {hasValue(title) ? (
            <HeaderWithHeadingOffset
              style={{ color: heading, marginTop: 0 }}
              id={strategy}
            >
              {title}
            </HeaderWithHeadingOffset>
          ) : null}
          {subTitle !== undefined && <p>{subTitle}</p>}
          <div style={{ minHeight: cardHeight }}>
            <SkeletonWrapper isLoading={carouselProducts === undefined}>
              <div style={{ minHeight: cardHeight, width: "100%" }}>
                <Slider
                  items={filteredProducts}
                  mode={SLIDER_MODE}
                  navigation={isLaptopSize ? "ARROWS" : "DOTS"}
                  slidesConfig={slidesConfig}
                />
              </div>
            </SkeletonWrapper>
          </div>
        </InnerWrapper>
      </Wrapper>
    </ProductListContainer>
  );
};

export const ProductRecommendations = withErrorBoundary(_Handler);
