// eslint-disable-next-line import/no-extraneous-dependencies
import { RecommendationStrategies } from "@/next-js-app/components/PersonalizedProductLists/Handler/Handler.helper";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { XxlButton } from "../Common/XxlButton";
import { Icon } from "../Icon";

export const FormButton = () => {
  const { t } = useTranslations();
  const handleClick = () =>
    document
      .getElementById(RecommendationStrategies.upsale)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  return (
    <XxlButton variant="secondary" onClick={handleClick}>
      {t("product.details.archive.form.button.label")}
      <Icon name="CaretRight" />
    </XxlButton>
  );
};
