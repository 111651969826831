import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { Stack } from "@mui/material";
import { MQ } from "react-app/src/styles/helpers";
import spaces from "react-app/src/styles/theme/spaces";
import { xxlTheme } from "react-app/src/styles/xxl-theme";
import { SiteContainer } from "../common/SiteContainer/SiteContainer";

export const PDPGrid = styled.div`
  ${MQ("laptop")} {
    display: grid;
    grid-gap: 46px;
    grid-template-columns: minmax(400px, 694px) minmax(250px, 600px);
    grid-template-areas: "left right";
  }
`;

export const OverviewGridArea = styled(Stack)`
  grid-area: right;
`;

export const ImageGridArea = styled(Stack)`
  grid-area: left;
  position: relative;
`;

type CampaignIconProps = {
  withMargin: boolean;
};

const desktopIconWidth = "70px";
const mobileIconWidth = "50px";

export const CampaignIcon = styled.div<CampaignIconProps>(
  ({ withMargin }) => css`
    width: ${mobileIconWidth};
    ${withMargin && `margin-top: ${xxlTheme.spaces.mini};`}

    ${MQ("tabletHorizontal")} {
      width: ${desktopIconWidth};
      ${withMargin && `margin-top: ${xxlTheme.spaces.smallRegular};`}
    }

    img {
      width: 100%;
    }
  `
);

export const BreadcrumbsContainer = styled.div`
  padding: ${spaces.smallRegular} 0;
  ${MQ("laptop")} {
    padding: ${spaces.large} 0;
  }
`;

export const ComponentContainer = styled(SiteContainer)<{
  hasTopMargin?: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ hasTopMargin = true }) => (hasTopMargin ? spaces.large : 0)};
  padding-bottom: 0;

  ${MQ("laptop")} {
    margin-top: ${({ hasTopMargin = true }) =>
      hasTopMargin ? spaces.almostHuge : 0};
    padding-bottom: 0;
  }
`;

export const ProductListContainer = styled(ComponentContainer)`
  max-width: 100vw;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  ${MQ("laptop")} {
    padding-left: 0;
    padding-right: 0;
  }

  & > div {
    box-sizing: border-box;
    /* paddings should correspond to the ones in PersonalizedWrapper component */
    padding-inline: ${spaces.smallRegular};
    ${MQ("laptop")} {
      padding-inline: ${spaces.mediumHuge};
    }
    ${MQ("bigDesktop")} {
      padding: 0 calc((100% - 1332px) / 2);
    }
  }
`;
