import { type RecommendationStrategy } from "@/next-js-app/components/PersonalizedProductLists/Handler/Handler.helper";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { ColorTheme } from "@xxl/content-api";
import { xxlTheme } from "../../styles/xxl-theme";
import type { TranslationKey } from "../../translations";
import { getXXLSessionCookie } from "../../utils/Cookie";
import { hexToRGB } from "../../utils/xxl-hex-to-rgb";

type Recommendation = {
  productsCount: number;
  recommendationsKey: string;
  strategy: RecommendationStrategy;
  brandNames?: string[];
  buttonUrl?: string;
  campaignIds?: string[];
  categoryCodes?: string[];
  categoryId?: string;
  days?: number;
  element?: HTMLDivElement;
  includedProducts?: string[];
  linkDisplayName?: string;
  linkUrl?: string;
  price?: number; // Used for minimum price in upsale strategy
  productStyleId?: string; // Used for the upsale strategy
  title?: string;
};

const INT_BASE = 10;
const PRODUCTS_COUNT_DEFAULT_VALUE = 12;
const DAYS_DEFAULT_VALUE = 7;

export const stringToRecommendationStrategy = (
  strategyString?: string
): RecommendationStrategy => {
  switch (strategyString) {
    case "bestseller":
      return "bestseller";
    case "cart":
      return "cart";
    case "frequently-bought-together":
      return "frequently-bought-together";
    case "personalized":
      return "personalized";
    case "popularity":
      return "popularity";
    case "recently":
      return "recently";
    case "upsale":
      return "upsale";
    default:
      return "personalized";
  }
};

const getProductsCount = (productsCountString?: string): number => {
  return productsCountString !== undefined && productsCountString.length > 0
    ? parseInt(productsCountString, INT_BASE)
    : PRODUCTS_COUNT_DEFAULT_VALUE;
};

const getDays = (daysString?: string): number => {
  const days =
    daysString !== undefined
      ? parseInt(daysString, INT_BASE)
      : DAYS_DEFAULT_VALUE;
  return days !== 0 ? days : DAYS_DEFAULT_VALUE;
};

const getStringArrayFromString = (string?: string): string[] | undefined => {
  if (string !== undefined && string.length !== 0 && string !== "[]") {
    return string
      .replace(/\[/g, "")
      .replace(/]/g, "")
      .replace(/, /g, ",")
      .split(",");
  }
  return undefined;
};

const getProductId = (code: string): string => {
  return code.split("_").shift() ?? "";
};

const getIncludedProducts = (
  strategy: string,
  productIdsString?: string,
  cartEntries?: string[]
): string[] => {
  if (strategy === "recently") {
    const xxlSessionCookie = getXXLSessionCookie();
    cartEntries = cartEntries?.map((val) => getProductId(val));
    return xxlSessionCookie.recentlyViewedProducts !== undefined
      ? xxlSessionCookie.recentlyViewedProducts.filter((val) =>
          cartEntries !== undefined
            ? !cartEntries.includes(getProductId(val))
            : true
        )
      : [];
  }
  return getStringArrayFromString(productIdsString) ?? [];
};

const getPrice = (price: string | undefined): number | undefined => {
  if (price !== "" && price !== undefined) {
    return Number(price);
  }
  return undefined;
};

const getTitle = (
  strategy: RecommendationStrategy
): TranslationKey | undefined => {
  switch (strategy) {
    case "upsale":
      return "carousel.upsale.title";
    case "recently":
      return "carousel.recently.viewed.title";
    case "personalized":
      return "carousel.personalized.title";
    case "frequently-bought-together":
      return "carousel.frequently.bought.together.title";
    default:
      return;
  }
};

export const getProductRecommendationsFromElement = (
  element: HTMLDivElement
): Recommendation => {
  const {
    categoryId,
    productsCount: productsCountString,
    recommendationsKey,
    title: titleString,
    strategy: strategyString,
    days: daysString,
    styleId: productStyleId,
    price: priceString,
    brandNames: brandNamesString,
    categoryCodes: categoryCodesString,
    campaignIds: campaignIdsString,
    productIds: productIdsString,
    cartEntries: cartEntriesString,
    buttonUrl,
    linkDisplayName,
    linkUrl,
  } = element.dataset;
  const price = getPrice(priceString);
  const productsCount = getProductsCount(productsCountString);
  const days = getDays(daysString);
  const strategy = stringToRecommendationStrategy(strategyString);
  const cartEntries = getStringArrayFromString(cartEntriesString);
  const brandNames = getStringArrayFromString(brandNamesString);
  const categoryCodes = getStringArrayFromString(categoryCodesString);
  const campaignIds = getStringArrayFromString(campaignIdsString);
  const includedProducts = getIncludedProducts(
    strategy,
    productIdsString,
    cartEntries
  );

  const title =
    titleString === "" || titleString === undefined
      ? getTitle(strategy)
      : titleString;

  if (recommendationsKey === undefined || recommendationsKey === "") {
    throw new Error(
      `Missing recommendationsKey for "${title ?? "unknown"}" module`
    );
  }

  return {
    categoryId,
    element,
    recommendationsKey,
    productsCount,
    title,
    strategy,
    productStyleId,
    price,
    days,
    includedProducts,
    brandNames,
    categoryCodes,
    campaignIds,
    buttonUrl,
    linkDisplayName,
    linkUrl,
  };
};

const { colors } = xxlTheme;

const defaultColorTheme = {
  backgroundColor: colors.xxlWhite,
  textColor: colors.xxlBlack,
};
const strategyColorThemes = [
  {
    strategy: "upsale",
    backgroundColor: colors.xxlLightBlue,
    textColor: colors.xxlBlue,
  },
  {
    strategy: "recently",
    backgroundColor: colors.xxlLightOrange,
    textColor: colors.xxlDarkOrange,
  },
  {
    strategy: "frequently_bought_together",
    backgroundColor: colors.xxlLightAmber,
    textColor: colors.xxlDarkAmber,
  },
  {
    strategy: "personalized",
    backgroundColor: colors.xxlLightTurquoise,
    textColor: colors.xxlDarkTurquoise,
  },
  {
    strategy: "popularity",
    backgroundColor: colors.xxlLightGreen,
    textColor: colors.xxlDarkGreen,
  },
  {
    strategy: "bestseller",
    backgroundColor: colors.xxlLightGreen,
    textColor: colors.xxlDarkGreen,
  },
];

const getCarouselColorTheme = (strategy?: RecommendationStrategy) =>
  strategyColorThemes.find((item) => item.strategy === strategy) ??
  defaultColorTheme;

const getColorThemeCss = (backgroundColor: string, textColor: string) => `
  background-color: ${hexToRGB(backgroundColor, 0.4)};
  color: ${textColor};
`;

export const productCarouselColorTheme = (
  type: RecommendationStrategy,
  colorTheme?: ColorTheme
) => {
  if (isNotNullOrUndefined(colorTheme)) {
    return getColorThemeCss(colorTheme.value, colorTheme.font);
  }
  const theme = getCarouselColorTheme(type);

  return getColorThemeCss(theme.backgroundColor, theme.textColor);
};
