/* eslint-disable import/no-extraneous-dependencies */
import { QueryClientProvider } from "next-js-app/src/components/Providers/QueryClientProvider";
import { SessionProvider } from "next-js-app/src/components/Providers/SessionProvider";
import type { FunctionComponent, PropsWithChildren } from "react";
import React from "react";
import { StyleProvider } from "../../StyleProvider";
import { ApiClientsProvider } from "./ApiClientsProvider";
import { ReactAppSharedDataProvider } from "./ReactAppSharedDataProvider";
import { TrackingContextProvider } from "./TrackingContextProvider";
import { TranslationsProvider } from "./TranslationsProvider";

const Providers: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <ReactAppSharedDataProvider>
      <SessionProvider isReactApp={true}>
        <ApiClientsProvider>
          <TrackingContextProvider>
            <TranslationsProvider>
              <QueryClientProvider>
                <StyleProvider>{children}</StyleProvider>
              </QueryClientProvider>
            </TranslationsProvider>
          </TrackingContextProvider>
        </ApiClientsProvider>
      </SessionProvider>
    </ReactAppSharedDataProvider>
  );
};

export { Providers };
