import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  CountdownTimerSettings,
  DescriptionField,
  DisclaimerInformation,
  Image,
  TaglineField,
  TitleField,
} from "@xxl/content-api";
import { useState } from "react";
import { useXxlMediaQuery } from "../../../hooks/useXxlMediaQuery";
import {
  ImageWithFetchPrio,
  LegacyBannerImageWrapper,
} from "../../Common/Image/ImageWithFetchPrio";
import { CountdownTimer } from "../../CountdownTimer";
import { BannerSize } from "../../PriceDisplays/price-display-helper";
import { CountdownTimeContainer } from "../Banner.styled";
import type { ImageSettings } from "../Shared/BannerContent/BannerContent.helper";
import {
  formatBannerText,
  getBannerText,
  getImagePriority,
  getImageProp,
} from "../Shared/BannerContent/BannerContent.helper";
import type { SanityTextColor } from "../types";
import {
  BackgroundWrapper,
  Button,
  Description,
  Headline,
  Label,
  TextWrapper,
  TransparentButtonWithIcon,
} from "./RegularBanner.styled";
import { handleImageError } from "../../../utils/xxl-image";

const bannerImageSizes = {
  xs: {
    sizes: `
    (max-width: 400px) 230w,
    (max-width: 600px) 300w,
    (max-width: 1279px) 500w,
    400w
  `,
    srcSetSizes: [
      { width: 400, size: "230w" },
      { width: 300, size: "300w" },
      { width: 500, size: "500w" },
      { width: 400, size: "400w" },
    ],
  },

  small: {
    sizes: `
    (max-width: 400px) 400w,
    (max-width: 600px) 600w,
    (max-width: 1279px) 880w,
    660w
  `,
    srcSetSizes: [
      { width: 400, size: "400w" },
      { width: 600, size: "600w" },
      { width: 880, size: "880w" },
      { width: 660, size: "660w" },
    ],
  },

  medium: {
    sizes: `
    (max-width: 400px) 400w,
    (max-width: 600px) 600w,
    (max-width: 1279px) 880w,
    660w
  `,
    srcSetSizes: [
      { width: 400, size: "400w" },
      { width: 600, size: "600w" },
      { width: 800, size: "800w" },
      { width: 1000, size: "1000w" },
      { width: 1279, size: "1279w" },
      { width: 660, size: "660w" },
    ],
  },
};

const BtnComponent = ({
  isExtraSmallBanner,
  textColor,
  buttonText,
  isHovered,
}: {
  isExtraSmallBanner: boolean;
  textColor: SanityTextColor;
  buttonText: string;
  isHovered: boolean;
}) => {
  if (isExtraSmallBanner) {
    return (
      <TransparentButtonWithIcon textColor={textColor}>
        {buttonText}
      </TransparentButtonWithIcon>
    );
  }
  return (
    <Button
      type="button"
      className={`button button--${
        textColor === "white" ? "secondary" : "primary"
      } button--small ${isHovered ? "button--accent" : ""}
    `}
    >
      {buttonText}
    </Button>
  );
};

type RegularBannerProps = {
  size: BannerSize;
  textColor: SanityTextColor;
  hasImageOverlay: boolean;
  url?: string;
  textPosition?: string;
  image?: Image;
  mobileImage?: Image;
  backgroundColor?: string;
  tagline?: string;
  title?: string;
  buttonText?: string;
  description?: string;
  descriptionField?: DescriptionField;
  countdownTimerSettings?: CountdownTimerSettings | null;
  isHighPrioComponent: boolean;
  taglineField?: TaglineField;
  titleField?: TitleField;
  disclaimerInformation?: DisclaimerInformation;
};

const RegularBanner = ({
  size,
  tagline,
  title,
  description,
  textPosition,
  image,
  textColor,
  hasImageOverlay,
  countdownTimerSettings,
  backgroundColor,
  url,
  buttonText,
  isHighPrioComponent,
  mobileImage,
  descriptionField,
  taglineField,
  titleField,
  disclaimerInformation,
}: RegularBannerProps) => {
  const isMobile = useXxlMediaQuery("MobileMediaQuery");
  const isBannerALink = Boolean(url);
  const isExtraSmallBanner = size === BannerSize.XS;
  const [isHovered, setIsHovered] = useState(false);
  const imagePriority = getImagePriority(isHighPrioComponent);

  const imageSettings: ImageSettings = {
    ...imagePriority,
    isMobile,
    sizes: bannerImageSizes[size].sizes,
    srcSetSizes: bannerImageSizes[size].srcSetSizes,
  };
  const backgroundImage = isNotNullOrUndefined(image)
    ? getImageProp(imageSettings, image, mobileImage)
    : undefined;

  const descriptionText = getBannerText(
    isMobile,
    descriptionField?.description,
    descriptionField?.mobileDescription,
    description
  );
  const taglineText = getBannerText(
    isMobile,
    taglineField?.taglineText,
    taglineField?.mobileTaglineText,
    tagline
  );
  const titleText = getBannerText(
    isMobile,
    titleField?.title,
    titleField?.mobileTitle,
    title
  );

  return (
    <BackgroundWrapper
      hasImageOverlay={hasImageOverlay}
      backgroundColor={backgroundColor}
      isBannerALink={isBannerALink}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-testid="regular-banner"
    >
      {backgroundImage !== undefined && (
        <LegacyBannerImageWrapper>
          <ImageWithFetchPrio
            data-private={true}
            sizes={backgroundImage.sizes}
            src={backgroundImage.url}
            srcSet={backgroundImage.srcSet}
            alt={backgroundImage.alt}
            fetchPriority={backgroundImage.fetchPriority}
            loading={backgroundImage.loading}
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
            onError={handleImageError}
          />
        </LegacyBannerImageWrapper>
      )}

      <TextWrapper
        textColor={textColor}
        textPosition={textPosition}
        hasDisclaimerInformation={hasValue(disclaimerInformation)}
      >
        {isNotNullOrUndefined(taglineText) && (
          <Label
            isExtraSmall={isExtraSmallBanner}
            textColor={taglineField?.color ?? undefined}
          >
            {formatBannerText(taglineText)}
          </Label>
        )}
        {isNotNullOrUndefined(titleText) && (
          <Headline
            isExtraSmall={isExtraSmallBanner}
            textColor={titleField?.color ?? undefined}
          >
            {formatBannerText(titleText)}
          </Headline>
        )}
        {isNotNullOrUndefined(descriptionText) && (
          <Description
            isExtraSmall={isExtraSmallBanner}
            textColor={descriptionField?.color ?? undefined}
          >
            {descriptionText}
          </Description>
        )}
        {countdownTimerSettings !== null &&
          countdownTimerSettings?.date !== undefined && (
            <CountdownTimeContainer isExtraSmall={isExtraSmallBanner}>
              <CountdownTimer
                date={new Date(countdownTimerSettings.date)}
                isBlackText={countdownTimerSettings.isBlack}
                isOnlyHours={countdownTimerSettings.isOnlyHours}
                description={countdownTimerSettings.description}
              />
            </CountdownTimeContainer>
          )}
      </TextWrapper>
      {buttonText !== undefined && isBannerALink && (
        <BtnComponent
          isExtraSmallBanner={isExtraSmallBanner}
          textColor={textColor}
          buttonText={buttonText}
          isHovered={isHovered}
        />
      )}
    </BackgroundWrapper>
  );
};

export default RegularBanner;
