import type { CountdownTimerSettings } from "@xxl/content-api";
import type {
  BaseProductData,
  ProductPageResult,
} from "@xxl/product-search-api";
import type { SerializableProductPageResult } from "../../utils/search-api-utils/product-data-maps";
import type { PriceComparatorWidgetConf } from "./PriceComparatorWidget";

export type CampaignCountdownProps = {
  endDate: Date;
  fontColor: string;
  convertDaysToHours?: boolean;
  startDate?: Date;
};

type ElevateMetaData = {
  customerKey: string;
  sessionKey: string;
  touchpoint: "MOBILE" | "DESKTOP";
};

export type VolumentalMetaData = {
  isFitstationEnabled: boolean;
  volumentalId: string;
};

export type EcoOnlineConfigData = {
  baseUrl: string;
  apiKey: string;
  shouldLoad: boolean;
};

export type PaymentProviderMessageConfig = {
  isActive: boolean;
  locale: string;
};

export type CampaignInfo = {
  colorTheme: {
    background: string;
    foreground: string;
  };
  id: string;
  name: string;
  timer: CountdownTimerSettings | null;
  description: string | null;
  title: string;
  slug: string;
};

type Metadata = {
  canonicalPageLink: string;
  jsonLdData: string;
  metaDescription: string;
  metaTitle: string;
};

export type PdpProps = {
  configurations: [];
  metadata: Metadata;
  relatedGuides: [];
  servicePromotions: [];
  ecoOnlineConfigData: EcoOnlineConfigData;
  elevateMetaData: ElevateMetaData;
  frontEndServerUrl: string;
  initialElevateProductPageData: SerializableProductPageResult;
  paymentProviderMessageConfig: PaymentProviderMessageConfig;
  priceComparatorWidgetConf: PriceComparatorWidgetConf | null;
  videolyId: string;
  volumentalMetaData: VolumentalMetaData;
  campaignInfo?: CampaignInfo;
};

export const StockLevels = {
  IN_STOCK: "INSTOCK",
  LOW_STOCK: "LOWSTOCK",
  OUT_OF_STOCK: "OUTOFSTOCK",
} as const;

export type StockLevel = (typeof StockLevels)[keyof typeof StockLevels];

export type Store = {
  collectableStockLevel: StockLevel;
  id: string;
  location: { latitude: number; longitude: number } | null;
  name: string;
  onlineStockLevel: StockLevel;
  stockLevel: StockLevel;
};

export const CART_LOADING_STATE = {
  READY: "READY",
  ATC_LOADING: "ATC_LOADING",
  CNC_LOADING: "CNC_LOADING",
} as const;

type ConfigurationSelection = {
  qualifier: string;
  value: string | null;
  ean: string | null;
};

export type Configuration = {
  configurations: ConfigurationSelection[];
  skiSelector?: boolean;
};

type HazardSymbols = string[];
type Documents = {
  sds_pdf?: string;
  sps_pdf?: string;
};
type ExternalDocuments = {
  links?: string[];
  files?: string[];
};
export type EcoProduct = {
  product_name?: string;
  supplier?: string;
  product_group?: string;
  area_of_use?: string;
  hazard_statements?: string;
  synonyms?: string;
  classification?: string;
  article_no?: string;
  date?: string;
  language?: number;
  description?: string;
  hazard_symbols?: HazardSymbols;
  documents?: Documents;
  external_documents?: ExternalDocuments;
};
export type EcoResponseProps = {
  data?: EcoProduct[];
  columns?: string[];
  limit?: number;
  noOfResults?: number;
};

export type ElevateProductPageData = Omit<ProductPageResult, "baseProduct"> & {
  baseProduct: BaseProductData;
};
