import { isNotNullOrUndefined } from "@xxl/common-utils";
import type { HomePageCategories, Image } from "@xxl/content-api";
import React, { useState, useEffect } from "react";
import { useSharedData } from "../../contexts/SharedData";
import { useApiClients } from "../../contexts/ApiClients";
import type { ArrayOfCategoryData } from "@xxl/pim-api";
import {
  CategoriesWrapper,
  CategoriesHeading,
  CategoriesList,
  CategoryItem,
  CategoryImage,
  CategoryLink,
  CategoryLinkText,
  SubCategoriesList,
  SubCategoryListItem,
  SubCategoryListLink,
  ShowMoreButton,
} from "./PopularCategories.styled";
import { useMediaQuery } from "@mui/material";
import {
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
} from "../../utils/xxl-screen";
import {
  BREAKPOINTS,
  useBreakpoint,
} from "../../hooks/useBreakpoint/useBreakpoint";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { useKeenSlider } from "keen-slider/react";
import { KEEN_SLIDER_CLASSES } from "../Common/Slider/Slider.helper";
import { Indicator, IndicatorContainer } from "../Common/Slider/Slider.styled";
import { getImageUrl, handleImageError } from "../../utils/xxl-image";
import Breakpoints from "../../styles/breakpoints.config";
import { mapCategoriesToDisplayData } from "./utils";
import { log } from "@xxl/logging-utils";
import { getImagePriority } from "../Banners/Shared/BannerContent/BannerContent.helper";

export type SubCategoryData = {
  name?: string;
  url?: string;
};

export type CategoryData = {
  image: Image;
  name: string;
  url: string;
  subCategories?: SubCategoryData[];
  code?: string;
};

const CATEGORIES_TO_DISPLAY = {
  tablet: 7,
  desktop: 11,
};

const dotsStyles: React.CSSProperties = {
  marginTop: "12px",
  marginBottom: 0,
};

export const PopularCategories: React.FunctionComponent<
  HomePageCategories & { isHighPrioComponent: boolean }
> = ({ title, level1Categories, isHighPrioComponent }) => {
  const [categoryIds, setCategoryIds] = useState<string[]>();
  const [categoriesData, setCategoriesData] = useState<ArrayOfCategoryData>();
  const [categoriesDisplayData, setCategoriesDisplayData] =
    useState<CategoryData[]>();
  const { siteUid } = useSharedData().data;
  const { pimApi } = useApiClients();
  const isMobile = useMediaQuery(mobileMediaQuery);
  const isTablet = useMediaQuery(mobileAndTabletMediaQuery);
  const breakpoint = useBreakpoint();
  const [categoriesToDisplay, setCategoriesToDisplay] = useState<number>(
    level1Categories?.length ?? 0
  );
  const [allCategoriesVisible, setAllCategoriesVisible] = useState(false);
  const { t } = useTranslations();
  const nrOfDots =
    isNotNullOrUndefined(categoriesDisplayData) &&
    categoriesDisplayData.length > 3
      ? categoriesDisplayData.length
      : 0;
  const dots = nrOfDots > 0 ? [...Array(nrOfDots).keys()] : [];
  const [currentSlide, setCurrentSlide] = useState(0);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLUListElement>({
    mode: "snap",
    loop: true,
    drag: true,
    slides: {
      perView: 2.5,
      spacing: 8,
    },
    breakpoints: {
      "(min-width: 768px)": {
        disabled: true,
      },
    },
    created() {
      setCurrentSlide(0);
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  const showMoreClickHandler = () => {
    if (isNotNullOrUndefined(level1Categories)) {
      setCategoriesToDisplay(level1Categories.length);
      setAllCategoriesVisible(true);
    }
  };

  const handleDotClick = (idx: number) => {
    instanceRef.current?.moveToIdx(idx);
  };

  useEffect(() => {
    if (isNotNullOrUndefined(level1Categories) && level1Categories.length > 0) {
      const categoryIdsArray = level1Categories
        .map((item) =>
          isNotNullOrUndefined(item) && isNotNullOrUndefined(item.ecomCode)
            ? item.ecomCode
            : ""
        )
        .filter((item) => item !== "");
      setCategoryIds(categoryIdsArray);
    }
  }, [level1Categories]);

  useEffect(() => {
    const _getCategories = async (categories: string): Promise<void> => {
      try {
        const categoriesResponse = await pimApi.getCategories(
          siteUid,
          categories
        );

        if (categoriesResponse.data.length > 0) {
          setCategoriesData(categoriesResponse.data);
        }
      } catch (err) {
        log.error("Cannot get categories data", err);
      }
    };
    if (isNotNullOrUndefined(categoryIds)) {
      void _getCategories(categoryIds.join(","));
    }
  }, [categoryIds, siteUid]);

  useEffect(() => {
    if (
      isNotNullOrUndefined(categoriesData) &&
      isNotNullOrUndefined(level1Categories)
    ) {
      setCategoriesDisplayData(
        mapCategoriesToDisplayData(categoriesData, level1Categories)
      );
    }
  }, [categoriesData, level1Categories]);

  useEffect(() => {
    if (!allCategoriesVisible && breakpoint === BREAKPOINTS.desktop) {
      setCategoriesToDisplay(CATEGORIES_TO_DISPLAY.desktop);
    } else if (
      !allCategoriesVisible &&
      (breakpoint === BREAKPOINTS.tablet || breakpoint === BREAKPOINTS.laptop)
    ) {
      setCategoriesToDisplay(CATEGORIES_TO_DISPLAY.tablet);
    } else {
      setCategoriesToDisplay(level1Categories?.length ?? 0);
    }
  }, [allCategoriesVisible, breakpoint, level1Categories?.length]);

  if (
    !isNotNullOrUndefined(categoriesDisplayData) ||
    categoriesDisplayData.length === 0
  ) {
    return null;
  }

  return (
    <CategoriesWrapper>
      {isNotNullOrUndefined(title) && (
        <CategoriesHeading>{title}</CategoriesHeading>
      )}
      <CategoriesList ref={sliderRef} className={KEEN_SLIDER_CLASSES.Slider}>
        {categoriesDisplayData.map((item, index) => {
          const srcset = `
            ${getImageUrl(item.image, 140, 140)} 140w,
            ${getImageUrl(item.image, 200, 200)} 200w, 
            ${getImageUrl(item.image, 212, 212)} 212w,
            ${getImageUrl(item.image, 242, 242)} 242w, 
            ${getImageUrl(item.image, 285, 285)} 285w, 
            ${getImageUrl(item.image, 300, 300)} 300w
          `;
          const sizes = `(max-width: ${
            Breakpoints.mobile
          }px) 140px, max-width: ${
            Breakpoints.tablet - 1
          }px) 300px, max-width: ${
            Breakpoints.tabletHorizontal - 1
          }px) 242px, (max-width: ${
            Breakpoints.desktop
          }px) 285px, (max-width: ${Breakpoints.bigDesktop}px) 212px, 200px`;

          if (index < categoriesToDisplay) {
            return (
              <CategoryItem key={index} className={KEEN_SLIDER_CLASSES.Slide}>
                <CategoryLink href={item.url}>
                  <CategoryImage
                    src={getImageUrl(item.image, 200, 200)}
                    alt={item.image.alt}
                    sizes={sizes}
                    srcSet={srcset}
                    onError={handleImageError}
                    {...getImagePriority(isHighPrioComponent)}
                  />
                  <CategoryLinkText>{item.name}</CategoryLinkText>
                </CategoryLink>
                {isNotNullOrUndefined(item.subCategories) &&
                  !isTablet &&
                  item.subCategories.length > 0 && (
                    <SubCategoriesList>
                      {item.subCategories.map((subCategory) => (
                        <SubCategoryListItem key={subCategory.name}>
                          <SubCategoryListLink href={subCategory.url}>
                            {subCategory.name}
                          </SubCategoryListLink>
                        </SubCategoryListItem>
                      ))}
                    </SubCategoriesList>
                  )}
              </CategoryItem>
            );
          }

          return null;
        })}
        {isNotNullOrUndefined(level1Categories) &&
          categoriesToDisplay < level1Categories.length &&
          !allCategoriesVisible && (
            <CategoryItem>
              <ShowMoreButton type="button" onClick={showMoreClickHandler}>
                {t("home.page.placement.categories.button.text")}
              </ShowMoreButton>
            </CategoryItem>
          )}
      </CategoriesList>
      {categoriesDisplayData.length > 3 && isMobile && (
        <IndicatorContainer
          className={KEEN_SLIDER_CLASSES.Dots}
          style={dotsStyles}
        >
          {dots.map((idx) => (
            <Indicator
              key={idx}
              aria-label={`slider-indicator-${idx + 1}`}
              onClick={() => handleDotClick(idx)}
              isActive={currentSlide === idx}
            />
          ))}
        </IndicatorContainer>
      )}
    </CategoriesWrapper>
  );
};
