import { hasValue } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import {
  RecommendationPayloadBuilder,
  type CustomAttributeType,
} from "@xxl/product-search-api";
import { useApiClients } from "../../contexts/ApiClients";
import { productKeysToLandingPageQuery } from "../../utils/ElevateApi/elevate-api.util";
import type {
  AdditionalSales,
  ProductCardDataV2,
} from "../../utils/ProductData/product-card-data-helper";
import {
  getAdditionalSalesProductIds,
  productCardDataToAdditionalSalesProducts,
  sortBaseProducts,
  toProductCardDataFromBase,
} from "../../utils/ProductData/product-card-data-helper";
import { useElevateRequestData } from "../useElevateRequestData/useElevateRequestData";
import { LIMIT } from "./constants";

type GetGeneralRecommendationsArgs = {
  variant: "PERSONAL" | "TOP_PRODUCTS";
  brandNames?: string[];
  campaignIds?: string[];
  categoryIds?: string[];
  maxNrOfProducts?: number;
  productKeys?: string[];
};

const useProductRecommendations = () => {
  const { elevateApi } = useApiClients();
  const { getBaseQuery } = useElevateRequestData();

  const getAdditionalSalesProducts = async (
    additionalSales?: AdditionalSales
  ): Promise<{
    accessoryProducts: ProductCardDataV2[];
    crossSalesProducts: ProductCardDataV2[];
    serviceProducts: ProductCardDataV2[];
  }> => {
    const fallback = {
      accessoryProducts: [],
      crossSalesProducts: [],
      serviceProducts: [],
    };

    if (!hasValue(additionalSales)) {
      return fallback;
    }

    const productKeys = hasValue(additionalSales)
      ? getAdditionalSalesProductIds(additionalSales)
      : [];

    if (productKeys.length === 0) {
      log.error("No products keys found.", additionalSales);
      return fallback;
    }

    const q = productKeysToLandingPageQuery(productKeys);

    if (q === null) {
      log.error("Query could not be created.", q);
      return fallback;
    }

    const response = await elevateApi.storefront.landingPagePOST(
      {
        ...getBaseQuery(),
        q,
        pageReference: `/p/getAdditionalSalesProducts/${q}`,
        channels: "ONLINE|STORE",
      },
      {}
    );

    const products = response.data.primaryList.baseProducts.map(
      toProductCardDataFromBase
    );

    return productCardDataToAdditionalSalesProducts(products, additionalSales);
  };

  const getAddToCartRecs = async (ean: string) => {
    const builder = new RecommendationPayloadBuilder();
    const recommendationLists = builder
      .addAddToCartRecs({
        id: "addToCartRecs1",
        limit: 4,
      })
      .build();

    const response = await elevateApi.storefront.addToCartPopup(
      {
        ...getBaseQuery(),
        variantKey: ean,
        channels: "ONLINE|STORE",
      },
      {
        recommendationLists,
      }
    );

    return response.data.recommendationLists[0];
  };

  const getProductPageRecommendationsData = async ({
    id,
    productKey,
    type,
  }: {
    id: string;
    productKey: string;
    type: "addAddToCartRecs" | "addAlternativesRecs" | "addRecentlyViewedRecs";
  }) => {
    const builder = new RecommendationPayloadBuilder();
    const recommendationLists = builder[type]({
      id,
      limit: LIMIT,
      productKey,
    }).build();

    const { data } = await elevateApi.storefront.productPage(
      {
        ...getBaseQuery(),
        productKey,
        channels: "ONLINE|STORE",
      },
      {
        recommendationLists,
      }
    );
    const { baseProducts = [] } = data.recommendationLists?.find(
      (item) => item.id === id
    ) ?? { baseProducts: [] };

    return baseProducts;
  };

  const getGeneralRecommendations = async ({
    brandNames,
    campaignIds,
    categoryIds,
    maxNrOfProducts,
    productKeys = [],
    variant,
  }: GetGeneralRecommendationsArgs) => {
    const builder = new RecommendationPayloadBuilder();
    const method =
      variant === "PERSONAL" ? "addPersonalRecs" : "addTopProductsRecs";
    const recommendationLists = builder[method]({
      id: "personalRecs",
      limit: maxNrOfProducts ?? LIMIT,
      ...(hasValue(brandNames) && { brandNames }),
      ...(hasValue(campaignIds) && { campaignIds }),
      ...(hasValue(categoryIds) && { categoryIds }),
    }).build();

    const isInStockOnlineString: CustomAttributeType = "isInStockOnline";
    const response = await elevateApi.storefront.landingPagePOST(
      {
        ...getBaseQuery(),
        q: productKeys.toString().replaceAll(",", " "),
        pageReference: `/homepage/recommendations`,
        channels: "ONLINE|STORE",
      },
      {
        primaryList: {
          include: hasValue(productKeys),
          productRules: `rule excl custom.${isInStockOnlineString} {"false"}`,
        },
        recommendationLists,
      }
    );

    const {
      primaryList: { baseProducts },
      recommendationLists: recommendationsListsResponse,
    } = response.data;
    const recommendationProducts = recommendationsListsResponse.flatMap(
      (recommendation) => recommendation.baseProducts
    );
    const sortedManuallyAddedProducts = sortBaseProducts({
      baseProducts,
      sortOrder: productKeys,
    });

    return [...sortedManuallyAddedProducts, ...recommendationProducts].slice(
      0,
      maxNrOfProducts
    );
  };

  const getAlternativesRecs = async (productKey: string) =>
    await getProductPageRecommendationsData({
      id: "alternativesRecs",
      productKey,
      type: "addAlternativesRecs",
    });

  const getFrequentlyBoughtTogether = async (productKey: string) =>
    await getProductPageRecommendationsData({
      id: "addToCartRecs",
      productKey,
      type: "addAddToCartRecs",
    });

  const getRecentlyViewed = async (productKey: string) =>
    await getProductPageRecommendationsData({
      id: "recentlyViewedRecs",
      productKey,
      type: "addRecentlyViewedRecs",
    });

  const getPersonalRecs = async ({
    brandNames,
    campaignIds,
    categoryIds,
    maxNrOfProducts,
    productKeys,
  }: Omit<GetGeneralRecommendationsArgs, "variant">) =>
    getGeneralRecommendations({
      variant: "PERSONAL",
      brandNames,
      campaignIds,
      categoryIds,
      maxNrOfProducts,
      productKeys,
    });

  const getTopProductsRecs = async ({
    brandNames,
    campaignIds,
    categoryIds,
    maxNrOfProducts,
    productKeys,
  }: Omit<GetGeneralRecommendationsArgs, "variant">) =>
    getGeneralRecommendations({
      variant: "TOP_PRODUCTS",
      brandNames,
      campaignIds,
      categoryIds,
      maxNrOfProducts,
      productKeys,
    });

  /**
   * getCartPageRecommendations returns recommendations based on the cart info provided.
   * @param productEANsInCart array of product EANs. Ex. ["1896787", "2384730"].
   */
  const getCartPageRecommendations = async (productEANsInCart: string[]) => {
    const builder = new RecommendationPayloadBuilder();
    const recommendationLists = builder
      .addCartPageRecs({
        id: "cartPageRecs",
        limit: LIMIT,
      })
      .build();
    const response = await elevateApi.storefront.cartPage(
      {
        ...getBaseQuery(),
        cart: productEANsInCart.toString().replace(",", "|"),
      },
      {
        recommendationLists,
      }
    );

    return response.data.recommendationLists[0].baseProducts;
  };

  return {
    getAdditionalSalesProducts,
    getAddToCartRecs,
    getAlternativesRecs,
    getCartPageRecommendations,
    getFrequentlyBoughtTogether,
    getPersonalRecs,
    getRecentlyViewed,
    getTopProductsRecs,
  };
};

export { useProductRecommendations };
