import { useSharedData } from "react-app/src/contexts/SharedData";
import { NextImage } from "../../common/NextImage/NextImage";
import { Chip } from "@mui/material";
import { getPublicationDateString } from "../../../../../react-app/src/components/guideCarousel/GuideCarouselHelper";
import { Description, Heading } from "./Guides.styled";
import { Text } from "react-app/src/components/Text/Text";
import { fontSizeScale } from "react-app/src/styles/theme/typography";
import { useXxlMediaQuery } from "react-app/src/hooks/useXxlMediaQuery/useXxlMediaQuery";
import { XxlStack } from "../../../../../react-app/src/components/Common/XxlStack/XxlStack";

import type { GuideContentData } from "@xxl/content-api";
import Link from "next/link";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { mobileTabletAndLaptopMediaQuery } from "react-app/src/utils/xxl-screen";
import { getImagePriority } from "@/react-components/Banners/Shared/BannerContent/BannerContent.helper";

type GuideCardProps = {
  title: string;
  metaDescription: string;
  image: GuideContentData["image"];
  publicationDate: GuideContentData["publicationDate"];
  url: GuideContentData["url"];
  isHighPrioComponent: boolean;
};

export const GuideCard = ({
  title,
  metaDescription,
  image,
  publicationDate,
  url,
  isHighPrioComponent,
}: GuideCardProps) => {
  const isLaptopSize = useXxlMediaQuery("LaptopMediaQuery");
  const {
    siteDefaultLanguage,
    requestMapping: { guides: guidesPath },
  } = useSharedData().data;

  if (!isNotNullOrUndefined(image)) {
    return null;
  }

  const hotspot = isNotNullOrUndefined(image.hotspot)
    ? {
        x: image.hotspot.x ?? 0,
        y: image.hotspot.y ?? 0,
      }
    : undefined;

  return (
    <div key={title}>
      <Link href={`/${guidesPath}/${url ?? ""}`}>
        <XxlStack spacing="12px">
          <NextImage
            src={image.url ?? ""}
            hotspot={hotspot}
            width={390}
            height={250}
            style={{ objectFit: "cover", width: "100%" }}
            alt={title}
            sizes={`${mobileTabletAndLaptopMediaQuery} 98vw, 434px`}
            {...getImagePriority(isHighPrioComponent)}
          />
          <Text
            sizeCustom={isLaptopSize ? fontSizeScale[4] : fontSizeScale[3]}
            fontFamily="bold"
          >
            <Heading as={"h3"}>{title}</Heading>
          </Text>
          <Description>{metaDescription}</Description>
          <XxlStack
            flexDirection={"row"}
            justifyContent={"flex-end"}
            mb={"2px"}
          >
            <Chip
              variant={"outlined"}
              label={getPublicationDateString(
                siteDefaultLanguage,
                publicationDate
              )}
            />
          </XxlStack>
        </XxlStack>
      </Link>
    </div>
  );
};
