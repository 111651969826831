import { hasValue } from "@xxl/common-utils";
import { log } from "@xxl/logging-utils";
import type { SetRequired } from "type-fest";
import { getSessionKey, getXXLCookie } from "../../utils/Cookie";
import type {
  ElevateContext,
  ElevateData,
  ElevateOrderConfirmationTrackingData,
  TrackFavoriteProductProps,
} from "./types";

const getUserKeys = async () => {
  const sessionKey = getSessionKey();
  const xxlCookie = await getXXLCookie();
  const customerKey = xxlCookie?.customerKey;

  if (!hasValue(customerKey)) {
    log.error("Could not get customerKey. Using fallback.");
  }

  if (!hasValue(sessionKey)) {
    log.error("Could not get sessionkey. Using fallback.");
  }

  return {
    customerKey: customerKey ?? crypto.randomUUID(),
    sessionKey: sessionKey,
  };
};

class Elevate {
  constructor(private readonly context: ElevateContext) {}

  sendAddToCartEvent = async (props: SetRequired<ElevateData, "ticket">) => {
    const {
      elevateApi: {
        storefront: { addToCart },
      },
      siteHost: site,
    } = this.context;
    void addToCart({ ...(await getUserKeys()), site }, props);
  };

  sendFavoritesEvent =
    (eventType: "add" | "remove") =>
    async ({ product: productKey }: TrackFavoriteProductProps) => {
      const {
        elevateApi: { storefront },
        siteHost: site,
      } = this.context;
      void storefront[`${eventType}Favorite`](
        { ...(await getUserKeys()), site },
        { productKey }
      );
    };

  sendClickEvent = async ({ ticket }: SetRequired<ElevateData, "ticket">) => {
    try {
      const {
        elevateApi: {
          util: { getFullClickUrl },
        },
        siteHost: site,
      } = this.context;
      const { customerKey, sessionKey } = await getUserKeys();
      const url = getFullClickUrl({ customerKey, sessionKey, site });
      const stringifiedData = JSON.stringify({ ticket });
      window.navigator.sendBeacon(url, stringifiedData);
    } catch (error) {
      log.error("Click event not sent.", error);
    }
  };

  sendOrderConfirmationEvent = async (
    eventData: ElevateOrderConfirmationTrackingData
  ) => {
    const {
      elevateApi: {
        admin: { paymentNotifications },
      },
      siteHost: site,
    } = this.context;
    void paymentNotifications({ ...(await getUserKeys()), site }, eventData, {
      validateStatus: () => true,
    });
  };
}

export { Elevate };
