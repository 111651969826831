import styled from "@emotion/styled/macro";
import { hasValue } from "@xxl/common-utils";
import type { USPData } from "@xxl/product-search-api";
import parse from "html-react-parser";
import { Text } from "react-app/src/components/Text";
import { typographyToCss } from "react-app/src/styles/helpers";
import { xxlTheme } from "react-app/src/styles/xxl-theme";

const Ul = styled.ul`
  padding: 0;
  margin: 0;
  margin-left: ${xxlTheme.spaces.small};
  display: flex;
  flex-direction: column;
  gap: ${xxlTheme.spaces.micro};
`;

const Li = styled.li`
  ${typographyToCss(xxlTheme.typography.mediumRegular)};
  letter-spacing: 0;
  word-break: break-word;
`;

type Props = {
  usps: USPData[];
};

export const Attributes = ({ usps }: Props) => {
  if (usps.length === 0) return <div />;
  return (
    <Ul>
      {usps.slice(0, 3).map(({ key, name, values, unit, valueType }) => {
        return (
          <Li key={key}>
            <Text as="span">{name}:&nbsp;</Text>
            <Text as="span">
              {values
                .map((value) => {
                  const parsedValue = (
                    valueType === "template" ? parse(value) : value
                  ) as string;
                  return hasValue(unit)
                    ? `${parsedValue} ${unit}`
                    : parsedValue;
                })
                .join(", ")}
            </Text>
          </Li>
        );
      })}
    </Ul>
  );
};
