import { useTranslations } from "@/react-app/contexts/Translations/TranslationsContext";
import { xxlTheme } from "@/react-app/styles/xxl-theme";
import { XxlStack } from "@/react-components/Common/XxlStack";
import { Icon } from "@/react-components/Icon/Icon";

export const PAYMENT_VARIANT = {
  WALLEY: "WALLEY",
  XXL_PAY: "XXL_PAY",
} as const;

type Props = {
  variant: keyof typeof PAYMENT_VARIANT;
};

const WalleyIconComponent = () => <Icon size={60} name="WalleyLogo" />;
const XxlPayIconComponent = () => <Icon size={90} name="XxlPay" />;

export const WalleyStaticWidget = ({ variant }: Props) => {
  const { t } = useTranslations();

  const IconComponent = {
    WALLEY: WalleyIconComponent,
    XXL_PAY: XxlPayIconComponent,
  }[variant];

  return (
    <XxlStack
      p={xxlTheme.spaces.mini}
      border={`1px solid ${xxlTheme.colors.xxlLightGrey}`}
      gap={xxlTheme.spaces.small}
    >
      {t("ab.test.pdp.walley")}
      <XxlStack height={xxlTheme.spaces.smallLarge}>
        <IconComponent />
      </XxlStack>
    </XxlStack>
  );
};
