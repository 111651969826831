import { isNotNullOrUndefined } from "@xxl/common-utils";

const parseJSON = (json: string) => {
  try {
    return JSON.parse(json) as Record<string, string>;
  } catch {
    return null;
  }
};

export const getIsEmployee = (cookieAccessToken: string): boolean => {
  let isEmployee = false;
  const [_, accessToken] = cookieAccessToken.split(".");
  if (typeof accessToken !== "string") {
    return isEmployee;
  }

  const claims = parseJSON(
    Buffer.from(accessToken, "base64").toString("utf-8")
  );

  if (claims === null) {
    return isEmployee;
  }
  if (
    isNotNullOrUndefined(claims["cognito:groups"]) &&
    claims["cognito:groups"].indexOf("employees") !== -1
  ) {
    isEmployee = true;
  }

  return isEmployee;
};
