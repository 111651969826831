import styled from "@emotion/styled/macro";
import { Button } from "../Common";
import { unsetButtonStyles } from "../Common/XxlButton/XxlButton";

export const DisclaimerInfoButton = styled(Button)`
  ${unsetButtonStyles}
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  min-width: 24px;
`;

export const Footer = styled.footer`
  display: flex;
  justify-content: end;
`;
