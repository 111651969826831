import type { CampaignInfo } from "@/components/Pdp/types";
import { hasValue } from "@xxl/common-utils";
import { color } from "@xxl/theme";
import { transformSizeIdToStyle } from "react-app/src/components/Product/product-helper";
import { urlParameterIsString } from "../../../src/utils/page-helper";
import type { SimpleCampaignData } from "../../utils/pdp-page-helper";
import { createColorTheme } from "../../utils/pdp-page-helper";

export const getProductCodeFromParameters = ({
  articleNumber,
  sizeCode,
  styleCode,
}: {
  articleNumber: string;
  styleCode?: string | string[];
  sizeCode?: string | string[];
}): string => {
  if (articleNumber.includes("_")) {
    return articleNumber;
  }
  if (hasValue(sizeCode)) {
    if (!urlParameterIsString(sizeCode)) {
      throw TypeError(`Expected sizeCode to be string, got ${typeof sizeCode}`);
    }
    return transformSizeIdToStyle(sizeCode);
  }
  if (hasValue(styleCode)) {
    if (!urlParameterIsString(styleCode)) {
      throw TypeError(
        `Expected styleCode to be string, got ${typeof styleCode}`
      );
    }
    return styleCode;
  }
  return `${articleNumber}_B`;
};

export const createCampaignInfo = ({
  campaignData,
  campaignRibbonContent,
  id,
}: {
  campaignData: SimpleCampaignData | null;
  campaignRibbonContent?: {
    backgroundColor?: string;
    frontColor?: string;
    text: string;
  };
  id?: string;
}): CampaignInfo | null => {
  if (campaignData === null || id === undefined) {
    return null;
  }

  const { description, slug, timer, title } = campaignData;
  const {
    backgroundColor = color.green.hex,
    frontColor = color.black.hex,
    text = "",
  } = campaignRibbonContent ?? {};

  return {
    colorTheme: createColorTheme({
      backgroundColor,
      foregroundColor: frontColor,
    }),
    description,
    id,
    name: text,
    slug,
    timer,
    title,
  };
};

export const isValueInString = (string = "", value = "") => {
  return string
    .split(",")
    .map((item) => item.trim())
    .includes(value);
};
