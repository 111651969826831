import type { UspClassification } from "../../utils/ProductAttributes/attributes-helper";
import { ProductSpecification, ProductSpecifications } from "./Product.styled";

type Props = {
  limit?: number;
  usps: UspClassification[];
  selectedColumnsNumber: number;
};

export const ProductClassifications = ({
  limit,
  usps,
  selectedColumnsNumber,
}: Props) => {
  const specifications = usps.slice(0, limit);

  if (specifications.length === 0) return null;

  return (
    <ProductSpecifications columnAmount={selectedColumnsNumber}>
      {specifications.map(({ name, value, id }, index) => (
        <ProductSpecification
          columnAmount={selectedColumnsNumber}
          key={`${id}-${index}`}
        >
          <span>
            <span>{`${name}: `}</span>
            <span>{value}</span>
          </span>
        </ProductSpecification>
      ))}
    </ProductSpecifications>
  );
};
