import type { Coordinates } from "@/next-js-app/components/common/NextImage/NextImage.types";
import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  FullGridBanner,
  HalfGridBanner,
  HorizontalGridBanner,
  Image,
  Product,
  VerticalGridBanner,
  DisclaimerInformation as DisclaimerInformationData,
} from "@xxl/content-api";
import type { ImageData } from "@xxl/frontend-api";
import { color, color as xxlColors } from "@xxl/theme";
import type * as CSSType from "csstype";
import parse from "html-react-parser";
import spaces from "../../../../styles/theme/spaces";
import { imageLoader } from "../../../../utils/image-loader/image-loader";
import { replaceNewLineCharactersWithBrElements } from "../../../../utils/xxl-string";
import type { VariantType } from "../../../Common/XxlButton/XxlButton";
import { CountdownTimer } from "../../../CountdownTimer";
import { SANITY_DEFAULT_QUALITY_LEVEL } from "../../../../utils/xxl-image";

type Text = {
  color: CSSType.Property.Color;
  text: string;
};

export type TextSize = {
  desktop: number;
  mobile: number;
};

export type Padding = {
  laptop: CSSType.Property.Padding;
  mobile: CSSType.Property.Padding;
};

export type BannerVariant = "full" | "half" | "vertical" | "horizontal";

type CommonBannerContentProps = {
  alignment: "LEFT" | "CENTER";
  hasMobileButtonBar: boolean;
  height: {
    mobile: CSSType.Property.Height;
    smallTablet: CSSType.Property.Height;
    desktop: CSSType.Property.Height;
  };
  padding: {
    buttonContainer: Padding;
    textContainer: Padding;
  };
  backgroundColor?: CSSType.Property.BackgroundColor;
  backgroundImage?: {
    alt: string;
    fetchPriority: "auto" | "high" | "low";
    loading: "eager" | "lazy";
    url: string;
    sizes: string;
    srcSet: string;
    hotspot?: Coordinates;
  };
  countdownTimer?: JSX.Element;
  description?: Text & {
    size: TextSize;
  };
  style?: React.CSSProperties;
  tagline?: Text;
  title?: Text & {
    size: TextSize;
    type: "h1" | "h2";
  };
  testid?: string;
  variant: BannerVariant;
  isScalable?: boolean;
  disclaimerInformation?: DisclaimerInformationData;
};

type LinkButton = {
  backgroundColor: typeof color.webBlack.hex | typeof color.white.hex;
  text: Text;
  url: string;
};

type WithButtons = CommonBannerContentProps & {
  linkVariant: "WITH_LINK_BUTTONS" | "WITH_GHOST_LINK_BUTTONS";
  links: LinkButton[];
};

type WithLink = CommonBannerContentProps & {
  linkVariant: "WITH_LINK";
  url: string;
};

type WithLinksAndButtons = CommonBannerContentProps & {
  linkVariant: "WITH_LINK_AND_BUTTONS";
  links: LinkButton[];
  url: string;
};

type LinkVariant =
  | WithButtons["linkVariant"]
  | WithLink["linkVariant"]
  | WithLinksAndButtons["linkVariant"];

export type BannerContentProps = WithButtons | WithLink | WithLinksAndButtons;

type Banner = (
  | FullGridBanner
  | HalfGridBanner
  | HorizontalGridBanner
  | VerticalGridBanner
) & { linkVariant?: LinkVariant };

export type ImageSettings = {
  fetchPriority: "auto" | "high" | "low";
  loading: "eager" | "lazy";
  isMobile: boolean;
  sizes: string;
  srcSetSizes: {
    size: string;
    width: number;
  }[];
};

export const disclaimerInformationIconSize = {
  mobile: 18,
  laptop: 24,
};

export const getUniqueProps = (props: BannerContentProps) => {
  const isWithLinkButton =
    props.linkVariant === "WITH_LINK_BUTTONS" ||
    props.linkVariant === "WITH_GHOST_LINK_BUTTONS";
  const isWithLink = props.linkVariant === "WITH_LINK";
  const isWithLinksAndButtons = props.linkVariant === "WITH_LINK_AND_BUTTONS";

  return {
    links: isWithLinkButton || isWithLinksAndButtons ? props.links : null,
    url: isWithLink || isWithLinksAndButtons ? props.url : null,
  };
};

export const getButtonVariant = (
  isLaptop: boolean,
  buttonColor: CSSType.Property.Color,
  linkVariant: LinkVariant,
  variant: BannerVariant
): VariantType => {
  if (
    linkVariant === "WITH_GHOST_LINK_BUTTONS" ||
    variant === "vertical" ||
    variant === "horizontal"
  ) {
    if (buttonColor === xxlColors.white.hex) {
      return "ghostWhite";
    }
    return "ghost";
  }

  if (isLaptop) {
    return buttonColor === xxlColors.webBlack.hex
      ? "secondaryInBanner"
      : "tertiaryInBanner";
  }

  return "secondaryInBanner";
};

const isBannerWithDescription = (
  banner: Banner
): banner is FullGridBanner | HalfGridBanner | VerticalGridBanner =>
  "descriptionField" in banner;

const isBannerWithBackgroundColor = (
  banner: Banner
): banner is HalfGridBanner | VerticalGridBanner | HorizontalGridBanner =>
  "backgroundColor" in banner;

const isBannerWithTagline = (
  banner: Banner
): banner is FullGridBanner | HalfGridBanner | VerticalGridBanner =>
  "tagline" in banner;

export const getImageProp = (
  imageSettings: ImageSettings,
  image: Image,
  mobileImage?: Image
): BannerContentProps["backgroundImage"] | undefined => {
  if (!isNotNullOrUndefined(imageSettings) && !isNotNullOrUndefined(image)) {
    return undefined;
  }

  const { fetchPriority, loading, isMobile, sizes, srcSetSizes } =
    imageSettings;
  const mobileUrl =
    mobileImage?.url ?? (mobileImage as ImageData | undefined)?.baseUrl;
  const shouldUseMobileImage = isMobile && mobileUrl !== undefined;
  const imageUrl = shouldUseMobileImage
    ? mobileUrl
    : image.url ?? (image as ImageData).baseUrl;
  const hotspot = shouldUseMobileImage ? mobileImage?.hotspot : image.hotspot;

  if (!isNotNullOrUndefined(imageUrl)) {
    return undefined;
  }

  return {
    alt:
      (shouldUseMobileImage ? mobileImage?.alt : image.alt) ?? "banner image",
    url: imageUrl,
    fetchPriority, //: index === 0 ? "high" : "low",
    loading,
    hotspot: isNotNullOrUndefined(hotspot)
      ? {
          x: hotspot.x ?? 0,
          y: hotspot.y ?? 0,
        }
      : undefined,
    sizes,
    srcSet: srcSetSizes.reduce((srcSet, { size, width }, index, arr) => {
      const isLastItem = arr.length === index + 1;
      return (
        srcSet +
        `${imageLoader(
          isNotNullOrUndefined(hotspot)
            ? { x: hotspot.x ?? 0, y: hotspot.y ?? 0 }
            : undefined
        )({
          backgroundColor: "transparent",
          src: imageUrl,
          width,
          quality: SANITY_DEFAULT_QUALITY_LEVEL,
        })} ${size}${isLastItem ? "" : ","}`
      );
    }, ""),
  };
};

const getTitleSize = (
  variant: BannerVariant,
  sizeSelector?: string
): TextSize => {
  if (variant === "full") {
    return {
      desktop: sizeSelector === "90" ? 90 : 60,
      mobile: sizeSelector === "90" ? 60 : 36,
    };
  }

  if (variant === "half") {
    return {
      desktop: 60,
      mobile: 36,
    };
  }

  return {
    desktop: 24,
    mobile: 20,
  };
};

const getDescriptionSize = (variant: BannerVariant): TextSize => {
  if (variant === "full" || variant === "half") {
    return {
      desktop: 24,
      mobile: 15,
    };
  }

  return {
    desktop: 15,
    mobile: 15,
  };
};

const dynamicPaddingLaptop =
  "clamp(16px, calc(16px + (46 - 16) * ((100vw - 768px) / (1380 - 768))), 46px)";
const dynamicPaddingMobile =
  "clamp(12px, calc(12px + (24 - 12) * ((100vw - 325px) / (768 - 325))), 24px)";

const getTextTopPadding = ({
  alignment,
  hasDisclaimerInformation,
  basePadding,
  iconSize,
  gap,
}: {
  alignment: "LEFT" | "CENTER";
  hasDisclaimerInformation: boolean;
  basePadding: string;
  iconSize: number;
  gap: string;
}): string => {
  if (alignment === "CENTER") {
    return basePadding;
  }

  if (!hasDisclaimerInformation) {
    return basePadding;
  }

  return `calc(${basePadding} + ${iconSize}px + ${gap})`;
};

const getTextPadding = (
  variant: BannerVariant,
  linkVariant: LinkVariant,
  hasDisclaimerInformation: boolean,
  alignment: "LEFT" | "CENTER"
): Padding => {
  if (variant === "full" || variant === "half") {
    const paddingTopLaptop = getTextTopPadding({
      alignment,
      hasDisclaimerInformation,
      basePadding: dynamicPaddingLaptop,
      iconSize: disclaimerInformationIconSize.laptop,
      gap: spaces.smallRegular,
    });

    const paddingTopMobile = getTextTopPadding({
      alignment,
      hasDisclaimerInformation,
      basePadding: dynamicPaddingMobile,
      iconSize: disclaimerInformationIconSize.mobile,
      gap: spaces.miniRegular,
    });

    const laptop =
      linkVariant === "WITH_LINK_BUTTONS"
        ? `${paddingTopLaptop} ${dynamicPaddingLaptop} 0`
        : `${dynamicPaddingLaptop}`;
    const mobile =
      linkVariant === "WITH_LINK_BUTTONS"
        ? `${paddingTopMobile} ${dynamicPaddingMobile} 0`
        : `${dynamicPaddingMobile}`;

    return {
      laptop,
      mobile,
    };
  }

  const paddingTopLaptop = getTextTopPadding({
    alignment,
    hasDisclaimerInformation,
    basePadding: spaces.large,
    iconSize: disclaimerInformationIconSize.laptop,
    gap: spaces.smallRegular,
  });

  const paddingTopMobile = getTextTopPadding({
    alignment,
    hasDisclaimerInformation,
    basePadding: spaces.smallRegular,
    iconSize: disclaimerInformationIconSize.mobile,
    gap: spaces.miniRegular,
  });

  const laptop =
    linkVariant === "WITH_LINK_BUTTONS"
      ? `${paddingTopLaptop} ${spaces.large} 0`
      : `${paddingTopLaptop} ${spaces.large} ${spaces.large}`;

  const mobile =
    linkVariant === "WITH_LINK_BUTTONS"
      ? `${paddingTopMobile} ${spaces.smallRegular} 0`
      : `${paddingTopMobile} ${spaces.smallRegular} ${spaces.smallRegular}`;

  return {
    laptop,
    mobile,
  };
};

const getButtonPadding = (
  variant: BannerVariant,
  linkVariant: LinkVariant
): Padding => {
  if (variant === "full" || variant === "half") {
    const laptop =
      linkVariant === "WITH_LINK_BUTTONS"
        ? `0 ${dynamicPaddingLaptop} ${dynamicPaddingLaptop}`
        : `${dynamicPaddingLaptop}`;
    const mobile = dynamicPaddingMobile;

    return {
      laptop,
      mobile,
    };
  }

  const laptop =
    linkVariant === "WITH_LINK_BUTTONS"
      ? `0 ${dynamicPaddingLaptop} ${dynamicPaddingLaptop}`
      : `${dynamicPaddingLaptop}`;
  const mobile = dynamicPaddingMobile;

  return {
    laptop,
    mobile,
  };
};

export type CountdownSizes = "base" | "small" | "big" | "tiny";
const countdownSizes: {
  mobile: { [key in BannerVariant]: CountdownSizes };
  laptop: { [key in BannerVariant]: CountdownSizes };
} = {
  mobile: {
    full: "base",
    half: "base",
    vertical: "base",
    horizontal: "small",
  },
  laptop: {
    full: "big",
    half: "big",
    vertical: "small",
    horizontal: "small",
  },
};

export const getBannerText = (
  isMobile?: boolean,
  text?: string,
  mobileText?: string,
  fallback?: string
) => {
  return isMobile === true && mobileText !== undefined
    ? mobileText
    : text ?? fallback;
};

export const toBannerContentProps = ({
  banner,
  height,
  variant,
  imageSettings,
  isLaptop,
  linkVariant,
  headingVariant,
}: {
  banner: Banner;
  height: BannerContentProps["height"];
  variant: BannerVariant;
  imageSettings?: ImageSettings;
  isLaptop: boolean;
  linkVariant?: LinkVariant;
  headingVariant?: "h1" | "h2";
}): BannerContentProps => {
  const {
    buttons,
    contentAlignment,
    image,
    mobileImage,
    countdownTimerSettings,
    redirectLink,
    titleField,
    disclaimerInformation,
  } = banner;
  const { isMobile } = imageSettings ?? {};

  const showLinkButtons = variant === "full" || variant === "half";

  const countdownSize = isLaptop
    ? countdownSizes.laptop[variant]
    : countdownSizes.mobile[variant];

  const countdownTimer = isNotNullOrUndefined(countdownTimerSettings) ? (
    <CountdownTimer
      startDate={
        countdownTimerSettings.startDate !== undefined
          ? new Date(countdownTimerSettings.startDate)
          : undefined
      }
      date={new Date(countdownTimerSettings.date)}
      description={countdownTimerSettings.description}
      isBlackText={countdownTimerSettings.isBlack}
      isOnlyHours={countdownTimerSettings.isOnlyHours}
      size={countdownSize}
      center={
        variant === "vertical" && isLaptop && contentAlignment === "center"
      }
    />
  ) : undefined;
  const descriptionField = isBannerWithDescription(banner)
    ? banner.descriptionField
    : undefined;

  const descriptionText = getBannerText(
    isMobile,
    descriptionField?.description,
    descriptionField?.mobileDescription
  );
  const description: BannerContentProps["description"] | undefined =
    isNotNullOrUndefined(descriptionField) && descriptionText !== undefined
      ? {
          text: descriptionText,
          color: hasValue(descriptionField.color)
            ? descriptionField.color
            : color.webBlack.hex,
          size: getDescriptionSize(variant),
        }
      : undefined;
  const taglineField = isBannerWithTagline(banner) ? banner.tagline : undefined;
  const taglineText = getBannerText(
    isMobile,
    taglineField?.taglineText,
    taglineField?.mobileTaglineText
  );
  const tagline: BannerContentProps["tagline"] | undefined =
    isNotNullOrUndefined(taglineField?.taglineText) && taglineText !== undefined
      ? {
          text: taglineText,
          color: hasValue(taglineField.color)
            ? taglineField.color
            : color.webBlack.hex,
        }
      : undefined;
  const alignment = contentAlignment === "center" ? "CENTER" : "LEFT";
  const backgroundColor = isBannerWithBackgroundColor(banner)
    ? banner.backgroundColor.value
    : undefined;

  const titleText = getBannerText(
    isMobile,
    titleField?.title,
    titleField?.mobileTitle
  );

  const commonProps: Omit<BannerContentProps, "linkVariant"> = {
    alignment,
    backgroundColor,
    backgroundImage:
      isNotNullOrUndefined(imageSettings) && isNotNullOrUndefined(image)
        ? getImageProp(imageSettings, image, mobileImage)
        : undefined,
    countdownTimer,
    description,
    height,
    hasMobileButtonBar:
      variant === "full" || variant === "half" || variant === "vertical",
    padding: {
      buttonContainer: getButtonPadding(
        variant,
        isNotNullOrUndefined(redirectLink) && !showLinkButtons
          ? "WITH_LINK"
          : "WITH_LINK_BUTTONS"
      ),
      textContainer: getTextPadding(
        variant,
        isNotNullOrUndefined(redirectLink) && !showLinkButtons
          ? "WITH_LINK"
          : "WITH_LINK_BUTTONS",
        hasValue(disclaimerInformation),
        alignment
      ),
    },
    tagline,
    ...(isNotNullOrUndefined(titleField) && {
      title:
        titleText !== undefined
          ? {
              color: titleField.color,
              text: titleText,
              size: getTitleSize(variant, titleField.sizeSelector),
              type:
                headingVariant !== undefined
                  ? headingVariant
                  : variant === "full"
                    ? "h1"
                    : "h2",
            }
          : undefined,
    }),
    variant,
    disclaimerInformation,
  };

  const withLinkProps: BannerContentProps = {
    ...commonProps,
    linkVariant: "WITH_LINK",
    url: redirectLink?.url ?? "",
  };

  const withButtonsProps: BannerContentProps = {
    ...commonProps,
    linkVariant:
      variant === "horizontal" || variant === "vertical"
        ? "WITH_GHOST_LINK_BUTTONS"
        : "WITH_LINK_BUTTONS",
    links:
      buttons?.map(({ displayName, url, buttonColor }) => ({
        text: {
          color:
            buttonColor?.toLowerCase() === color.webBlack.hex.toLowerCase()
              ? color.white.hex
              : color.webBlack.hex,
          text: displayName,
        },
        url,
        backgroundColor:
          buttonColor === color.webBlack.hex ? buttonColor : color.white.hex,
      })) ?? [],
  };

  if (
    isNotNullOrUndefined(redirectLink) &&
    isNotNullOrUndefined(buttons) &&
    linkVariant === "WITH_LINK_AND_BUTTONS"
  ) {
    return {
      ...withLinkProps,
      ...withButtonsProps,
      url: redirectLink.url,
      linkVariant,
    };
  }

  if (isNotNullOrUndefined(redirectLink)) {
    return {
      ...withLinkProps,
    };
  }

  if (isNotNullOrUndefined(buttons)) {
    return {
      ...withButtonsProps,
    };
  }

  throw Error("Banner has incorrect configuration.");
};

export const componentIndicesOnHomepageWithHighPrio = [0, 1];
export const isHomepageHighPrioComponent = (componentIndex: number) =>
  componentIndicesOnHomepageWithHighPrio.includes(componentIndex);
const getFetchPriority = (isHighPrioComponent: boolean): "high" | "low" =>
  isHighPrioComponent ? "high" : "low";
const getLoading = (isHighPrioComponent: boolean): "eager" | "lazy" =>
  isHighPrioComponent ? "eager" : "lazy";
export const getImagePriority = (isHighPrioComponent: boolean) => ({
  fetchPriority: getFetchPriority(isHighPrioComponent),
  loading: getLoading(isHighPrioComponent),
});

export const getBannerHeights = () => {
  const sharedHeight = {
    mobile: "auto",
    desktop: "auto",
    smallTablet: "auto",
  };

  return {
    fullGrid: sharedHeight,
    halfGrid: sharedHeight,
    quarterHalfGrid: {
      ...sharedHeight,
      ...{
        smallTablet: "auto",
      },
    },
    quarterVertical: {
      ...sharedHeight,
      ...{
        smallTablet: "auto",
      },
    },
    quarterHorizontal: {
      mobile: "auto",
      smallTablet: "auto",
      desktop: "auto",
    },
  };
};

export const isProduct = (item?: Product): item is Product =>
  isNotNullOrUndefined(item);

export const ProductBannerImageSettings = {
  xs: {
    sizes: `
      (max-width: 400px) 400w,
      (max-width: 600px) 600w,
      (max-width: 800px) 800w,
      (max-width: 1000px) 1000w,
      (max-width: 1279px) 1279w,
      660w
    `,
    srcSetSizes: [
      { width: 400, size: "400w" },
      { width: 600, size: "600w" },
      { width: 880, size: "880w" },
      { width: 660, size: "660w" },
    ],
  },

  small: {
    sizes: `
      (max-width: 400px) 400w,
      (max-width: 600px) 600w,
      (max-width: 800px) 800w,
      (max-width: 1000px) 1000w,
      (max-width: 1279px) 1279w,
      660w
    `,
    srcSetSizes: [
      { width: 400, size: "400w" },
      { width: 600, size: "600w" },
      { width: 880, size: "880w" },
      { width: 660, size: "660w" },
    ],
  },

  medium: {
    sizes: `
      (max-width: 400px) 400w,
      (max-width: 600px) 600w,
      (max-width: 800px) 800w,
      (max-width: 1000px) 1000w,
      (max-width: 1279px) 1279w,
      660w
    `,
    srcSetSizes: [
      { width: 400, size: "400w" },
      { width: 600, size: "600w" },
      { width: 880, size: "880w" },
      { width: 660, size: "660w" },
    ],
  },
};

export const formatBannerText = (text: string) =>
  parse(replaceNewLineCharactersWithBrElements(text));
