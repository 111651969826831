import React from "react";
import type { BrandsDataProps } from "../SearchState";
import { BrandWrapper, Image, Link, BrandName } from "./Brand.styled";
import Breakpoints from "../../../styles/breakpoints.config";
import { isNotEmpty } from "@xxl/common-utils";
import { useTracking } from "../../../contexts/Tracking";
import { handleImageError } from "../../../utils/xxl-image";
import { getImagePriority } from "../../Banners/Shared/BannerContent/BannerContent.helper";

type BrandProps = {
  brand: BrandsDataProps;
  isHighPrioComponent?: boolean;
};

const getCapitalizedName = (brand: string): string => {
  const [first, ...rest] = brand;
  return `${first.toUpperCase()}${rest.join("")}`;
};

export const Brand: React.FunctionComponent<BrandProps> = ({
  brand,
  isHighPrioComponent = false,
}) => {
  const tracking = useTracking();

  const { logo, name, code, brandUrl, ticket } = brand;
  const href =
    typeof code !== "boolean" && typeof name !== "boolean"
      ? `/b/${code}/${name}`
      : brandUrl ?? "";
  const url =
    typeof logo !== "boolean" &&
    logo.url !== undefined &&
    `${logo.url}?auto=format&fit=fill&max&w=80`;
  const srcset =
    typeof logo !== "boolean" &&
    logo.url !== undefined &&
    `${logo.url}?auto=format&fit=fill&max&w=50 50w, ${logo.url}?auto=format&fit=fill&max&w=80 80w`;
  const sizes = `(max-width: ${Breakpoints.tablet}px) 50px,
  80px`;

  const handleClickTracking = () => {
    isNotEmpty(ticket) && tracking.sendClickEvent({ ticket });
  };

  return (
    <BrandWrapper onClick={handleClickTracking}>
      <Link href={href}>
        {typeof logo !== "boolean" &&
        typeof url !== "boolean" &&
        isNotEmpty(logo.url) ? (
          <Image
            data-private={true}
            alt={logo.alt}
            srcSet={typeof srcset === "string" ? srcset : undefined}
            sizes={sizes}
            src={url}
            width="80"
            height="80"
            onError={handleImageError}
            {...getImagePriority(isHighPrioComponent)}
          />
        ) : (
          <BrandName>
            {typeof name !== "boolean" && getCapitalizedName(name)}
          </BrandName>
        )}
      </Link>
    </BrandWrapper>
  );
};
