import React from "react";
import type { BrandsDataProps } from "../SearchState";
import { Brand } from "./Brand";
import { BrandListWrapper } from "./BrandList.styled";

type BrandListProps = {
  brands: BrandsDataProps[];
  isFullWidth?: boolean;
  isHighPrioComponent?: boolean;
};
export const BrandList: React.FunctionComponent<BrandListProps> = ({
  brands,
  isFullWidth = false,
  isHighPrioComponent = false,
}) => {
  return (
    <BrandListWrapper
      data-testid="brand-list"
      className={!isFullWidth ? "container" : "brand-list"}
    >
      {brands.map((brand) => (
        <Brand
          key={brand.id}
          brand={brand}
          isHighPrioComponent={isHighPrioComponent}
        />
      ))}
    </BrandListWrapper>
  );
};
